import React from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon, XCircleIcon } from '@heroicons/react/20/solid'

export const ErrorNotification = ({error, setError}: any) => {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition show={error}>
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-50 border border-red-100 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <XCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-red-800">Somehing went wrong:</p>
                  <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    onClick={() => {setError('')}}
                    className="inline-flex rounded-md bg-red-50 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}