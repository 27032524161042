import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import DashboardHolder from './DashboardHolder'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  CheckCircleIcon,
  Cog8ToothIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import { SEOHeaders } from "./SeoHeaders"

interface AgentViewRunProps {
  navigate: any
  params: any
}

type AgentViewRunStates = {
  agentid: string
  name: string
  prompt: string
  run: any
  runId: string
}

class AgentViewRun extends Component <AgentViewRunProps, AgentViewRunStates> {
  loadInterval: any = undefined
  constructor (props: AgentViewRunProps) {
    super(props)
    this.state = {
      agentid: '',
      name: '',
      prompt: '',
      run: undefined,
      runId: ''
    }
  }

  componentDidMount(){
    if (this.props.params.agent) {
      this.loadAgent()
      this.loadRun()
      this.startRunInterval()
    }
  }
  
  componentWillUnmount() {
    if (this.loadInterval) {
      clearInterval(this.loadInterval)
    }
  }

  startRunInterval = () => {
    if (this.loadInterval === undefined) {
      this.loadInterval = setInterval(()=>this.loadRun(), 5000)
    }
  }
  
  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AgentViewRunStates)
  }

  loadAgent = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents/'+this.props.params.agent, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            agentid: json.agent.id,
            name: json.agent.name,
            prompt: json.agent.prompt
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadRun = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents/'+this.props.params.agent+'/runs/'+this.props.params.run, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({ run: json.run })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <DashboardHolder>
        <div className='h-screen flex flex-col'>
          <SEOHeaders title={'Run '+ (this.state.run ? '#'+this.state.run.id : '')+' of Agent "'+ this.state.name +'"'} appendTitle/>
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
            <div className="lg:flex lg:items-center lg:justify-between pb-4">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/d/'+this.props.params.namespace+'/a'} className="font-medium text-gray-700 hover:text-gray-900">
                          Agents
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-500" aria-hidden="true" />
                        <Link to={'/d/'+this.props.params.namespace+'/a/'+this.props.params.agent} className="ml-4 font-medium text-gray-700 hover:text-gray-900">
                          {this.state.name}
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-500" aria-hidden="true" />
                        <Link to="#" className="ml-4 font-medium text-gray-700 hover:text-gray-900">
                          Run <span className='pl-2 font-mono'>#{this.state.run && this.state.run.id}</span>
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          
          <div className="flex justify-between mx-4 sm:mx-6 lg:mx-8 pt-2 pb-4 block text-gray-900 border-b border-gray-300">
            <div>Response</div>
            <div className='text-sm text-gray-500'>
              {this.state.run&& this.state.run.status === 'working' ?
                <div className="flex flex-row">
                  <Cog8ToothIcon className="w-5 h-5 mr-2 animate-spin" />
                  Working
                </div>
              :''}
              {this.state.run&& this.state.run.status === 'completed' ?
                <div className="flex flex-row">
                  <CheckCircleIcon className="w-5 h-5 mr-2" />
                  Completed
                </div>
              :''}
            </div>
          </div>

          <div className='flex flex-col overflow-y-auto flex-1 px-4 sm:px-6 lg:px-8 pt-2 pb-4'>
            { this.state.run && this.state.run.response ? <Markdown className='markdownstyles' remarkPlugins={[remarkGfm]}>{this.state.run.response}</Markdown> : '' }
          </div>
        </div>

      </DashboardHolder>
    );
  }
}

export default function AgentViewRunWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <AgentViewRun params={params} navigate={navigate}/>
}