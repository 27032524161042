import React, { Fragment, Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import ProfileHolder from './ProfileHolder'
import { SEOHeaders } from "./SeoHeaders"

interface MemberInvitesProps {
  navigate: any
  params: any
}

type MemberInvitesStates = {
  invites: any[]
}

class MemberInvites extends Component <MemberInvitesProps, MemberInvitesStates> {
  constructor (props: MemberInvitesProps) {
    super(props)
    this.state = {
      invites: []
    }
  }

  componentDidMount(){
    this.loadMemberInvites()
  }
  
  loadMemberInvites = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/invites', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            invites: json.invites
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  denyInvite = (namespaceId: string) => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/denyinvite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          namespace: namespaceId
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadMemberInvites()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  acceptInvite = (namespaceId: string) => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/acceptinvite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          namespace: namespaceId
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadMemberInvites()
          this.props.navigate('/d/'+namespaceId+'/c')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <ProfileHolder>
        <SEOHeaders title={'Member Invites'} appendTitle/>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              Invites to spaces
            </p>
          </div>
        </div>
        
        <div className="mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                  Space
                </th>
                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                  Accept
                </th>
                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                  Refuse
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {this.state.invites.map((invite) => (
                <tr>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-0">
                    {invite.name}
                  </td>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                    <div className='text-gray-500 hover:text-gray-700 cursor-pointer' onClick={()=>this.acceptInvite(invite.id)}>
                      Accept
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                    <div className='text-gray-500 hover:text-gray-700 cursor-pointer' onClick={()=>this.denyInvite(invite.id)}>
                      Deny
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </ProfileHolder>
    );
  }
}

export default function MemberInvitesWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <MemberInvites params={params} navigate={navigate}/>
}