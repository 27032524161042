import React, { Fragment, Component } from 'react';
import { Menu, MenuButton, Dialog, DialogPanel, Transition, TransitionChild, Listbox } from '@headlessui/react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import { getCredentials } from './utils'
import { config } from './config'

import {
  Bars3Icon,
  XMarkIcon,
  DocumentTextIcon,
  SparklesIcon,
  ChatBubbleLeftIcon,
  Cog6ToothIcon,
  ListBulletIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'

const navigation = [
  { name: 'AI Chat', href: '/c', icon: ChatBubbleLeftIcon },
  { name: 'Pages', href: '/p', icon: DocumentTextIcon },
  { name: 'Feed', href: '/f', icon: ListBulletIcon },
  { name: 'Agents', href: '/a', icon: SparklesIcon },
  { name: 'Settings', href: '/o', icon: Cog6ToothIcon }
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface DashboardHolderProps {
  navigate: any
  params: any
  children: any
  location: any
  paddings: boolean
  side: any
}

type DashboardHolderStates = {
  user: any
  namespaces: any[]
  sidebarOpen: boolean
}

class DashboardHolder extends Component <DashboardHolderProps, DashboardHolderStates> {
  constructor (props: DashboardHolderProps) {
    super(props)
    this.state = {
      user: undefined,
      namespaces: [],
      sidebarOpen: false
    }
  }

  componentDidMount(){
    this.loadProfile()
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            user: json.data,
            namespaces: json.data.userMemberships.map((membership: any) => {
              return {
                id: membership.namespace.id,
                name: membership.namespace.name,
              }
            })
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }
  
  render () {
    /*
    // get selected namespace if need logo? or name?
    let spaceName = 'Select'
    if (this.props.params.namespace && this.state.user && this.state.namespaces.length > 0) {
      spaceName = this.state.namespaces.find((el: any)=>el.id === this.props.params.namespace).name
    }
    */
    const active = navigation.find((n) => {
      return '/d/'+this.props.params.namespace+n.href === this.props.location.pathname.substring(0, 27)
    })
    return (
      <>
        <Transition show={this.state.sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={()=>this.setState({sidebarOpen: false})}>
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-0 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-0 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative flex flex-1">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-in-out duration-0"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className={`absolute top-0 h-0 flex w-16 justify-center pt-5 ${this.props.side ? 'pl-[345px]' : 'pl-24'}`}>
                      <button type="button" className="mt-1.5 ml-16" onClick={()=>this.setState({sidebarOpen: false})}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex flex-col flex-shrink-0 gap-y-5 border-r border-gray-200 bg-gray-50 w-16">
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col">
                        <li>
                          <ul className="space-y-1">
                            <li>
                              <Menu as="div" className="relative flex-shrink-0 pt-4 pb-6">
                                <Menu.Button className="rounded-md w-full ps-2 pe-2 pb-2 pt-2 text-sm font-medium text-black flex flex-row text-gray-400 hover:text-blue-500 items-center justify-center">
                                  <img
                                    className="h-6 w-auto"
                                    src="/vortn-logo.svg"
                                    alt="Vortn"
                                  />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute left-4 z-10 mt-2 origin-top-right rounded-md bg-white py-1 focus:outline-none border border-gray-200">
                                    {this.state.user && this.state.namespaces.map((namespace: any)=>{
                                      return <Menu.Item key={namespace.id}>
                                        {({ active }) => (
                                          <Link
                                            id={namespace.id}
                                            to={'/d/'+namespace.id+'/c'}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700'
                                            )}
                                          >
                                            {namespace.name}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    })}
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to={'/new'}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700 border-t mt-1'
                                          )}
                                        >
                                          New Space
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <ul className="space-y-1">
                            {this.props.params.namespace ? navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={'/d/'+this.props.params.namespace+item.href}
                                  className={classNames(
                                    active !== undefined && item.href === active.href
                                      ? 'text-blue-600'
                                      : 'text-gray-700 hover:text-blue-600',
                                    'group flex gap-x-3 rounded-md px-2 py-2 text-sm leading-6 font-semibold items-center justify-center'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      active !== undefined && item.href === active.href
                                        ? 'text-blue-500' : 'text-gray-400 group-hover:text-blue-500',
                                        'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                            )):null}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {this.props.side ?
                    <div className='bg-white' onClick={()=>this.setState({sidebarOpen: false})}>
                      {this.props.side}
                    </div>
                  :null}
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex">
          <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-gray-50 w-16">
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col">
                <li>
                  <ul className="space-y-1">
                    <li>
                      <Menu as="div" className="relative flex-shrink-0 pt-4 pb-6">
                        <Menu.Button className="rounded-md w-full ps-2 pe-2 pb-2 pt-2 text-sm font-medium text-black flex flex-row text-gray-400 hover:text-blue-500 items-center justify-center">
                          <img
                            className="h-6 w-auto"
                            src="/vortn-logo.svg"
                            alt="Vortn"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-4 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 border border-gray-200 focus:outline-none">
                            {this.state.namespaces.map((namespace)=>{
                              return <Menu.Item key={namespace.id}>
                                {({ active }) => (
                                  <Link
                                    id={namespace.id}
                                    to={'/d/'+namespace.id+'/c'}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {namespace.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            })}
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={'/new'}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 border-t mt-1'
                                  )}
                                >
                                  New Space
                                </Link>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className="space-y-1">
                    {this.props.params.namespace ? navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={'/d/'+this.props.params.namespace+item.href}
                          className={classNames(
                            active !== undefined && item.href === active.href
                              ? 'text-blue-600'
                              : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                            'group flex rounded-md px-2 py-2 text-sm leading-6 font-semibold items-center justify-center'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              active !== undefined && item.href === active.href
                                ? 'text-blue-500' : 'text-gray-400 group-hover:text-blue-500',
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        </Link>
                      </li>
                    )): null}
                  </ul>
                </li>
                <li className="mt-auto pb-2">
                  <Link
                    to={'/profile'}
                    className="flex gap-x-4 w-full px-2 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 items-center justify-center"
                  >
                    {/*
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    */}
                    <UserCircleIcon className="h-6 w-6 text-gray-400 hover:text-blue-500" aria-hidden="true" />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            {this.props.side}
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 sm:px-6 lg:hidden">
          <button type="button" className="text-gray-700 lg:hidden" onClick={()=>this.setState({sidebarOpen: true})}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Vortn</div>
          <Link to={'/profile'}>
            {/*
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            */}
            <UserCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </Link>
        </div>

        <main className="lg:pl-16 bg-white h-full">
          {this.props.children}
        </main>
      </>
    );
  }
}

export default function DashboardHolderWithBonus(props: any) {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  return <DashboardHolder params={params} navigate={navigate} side={props.side ? props.side : null} children={props.children} location={location} paddings={props.paddings !== undefined ? props.paddings : true}/>
}