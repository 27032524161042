import React, { Component } from 'react'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import DashboardHolder from './DashboardHolder'

const tabs: any[] = [
  { name: 'General', href: '/profile', current: true },
  { name: 'Password', href: '/password', current: false },
  { name: 'API Keys', href: '/apis', current: false },
  { name: 'Invites', href: '/memberinvites', current: false }
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ProfileHolderProps {
  navigate: any
  params: any
  location: any
  children: any
}

type ProfileHolderStates = {

}

class ProfileHolder extends Component <ProfileHolderProps, ProfileHolderStates> {
  constructor (props: ProfileHolderProps) {
    super(props)
    this.state = {

    }
  }
  
  render () {
    return (
      <DashboardHolder>

        <div className='h-screen flex flex-col'>
          
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/profile'
                        } className="font-medium text-gray-700 hover:text-gray-900">
                          Profile Settings
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
              <div className="mt-8">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                    defaultValue={tabs.find((tab) => tab.href === this.props.location.pathname).name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <Link
                        key={tab.name}
                        to={tab.href}
                        className={classNames(
                          tab.href === this.props.location.pathname
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.href === this.props.location.pathname ? 'page' : undefined}
                      >
                        {tab.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col overflow-y-auto flex-1 px-4 sm:px-6 lg:px-8 pt-6 pb-4'>
            {this.props.children}
          </div>
          
        </div>
      </DashboardHolder>
    );
  }
}

export default function ProfileHolderWithBonus(props: any) {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  return <ProfileHolder params={params} navigate={navigate} location={location} children={props.children}/>
}