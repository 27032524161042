import React, { Fragment, Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Combobox } from '@headlessui/react'
import SettingsHolder from './SettingsHolder'
import { Dialog, Transition } from '@headlessui/react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ArrowLongLeftIcon, ChevronDownIcon, ArrowLongRightIcon, CheckIcon, XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { SEOHeaders } from "./SeoHeaders"
import { TrashIcon, MagnifyingGlassIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { Error } from './Error'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const accessLevels: any = {
  '0': 'Member',
  '100': 'Admin'
}

const usersPerPage = 25

interface GroupUsersProps {
  navigate: any
  params: any
}

type GroupUsersStates = {
  group: any
  users: any[]
  usersCount: number
  search: string
  page: number
  name: string
  value: string
  newModalOpen: boolean
  deleteModalOpen: boolean
  deleteUserId: string
  error: string
  newUserSearch: string
  newUserSearchResults: any[]
  selectedUser: any
  newUser: string
  access: number
  editUserId: string
  editModalOpen: boolean
}

class GroupUsers extends Component<GroupUsersProps, GroupUsersStates> {
  constructor(props: GroupUsersProps) {
    super(props)
    this.state = {
      group: undefined,
      users: [],
      usersCount: 0,
      search: '',
      page: 0,
      name: '',
      value: '',
      newModalOpen: false,
      deleteModalOpen: false,
      deleteUserId: '',
      error: '',
      newUserSearch: '',
      newUserSearchResults: [],
      selectedUser: { fullname: '' },
      newUser: '',
      access: 0,
      editUserId: '',
      editModalOpen: false
    }
  }

  componentDidMount() {
    this.loadGroup()
    this.loadGroupUsers()
  }

  loadGroup = () => {
    const authToken = getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/namespace/${this.props.params.namespace}/groups/${this.props.params.group}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            group: json.group
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadGroupUsers = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.search) {
      search = '&search=' + this.state.search
    }
    fetch(
      `${config.app.apiUri}/api/v1/namespace/${this.props.params.namespace}/groups/${this.props.params.group}/users?limit=${usersPerPage}&offset=${this.state.page * usersPerPage}${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            users: json.users,
            usersCount: json.count
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadMembers = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.search) {
      search = '&search=' + this.state.search
    }
    fetch(
      `${config.app.apiUri}/api/v1/namespace/${this.props.params.namespace}/members?limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            newUserSearchResults: json.members
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteGroupUser = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/' + this.props.params.namespace + '/groups/' + this.props.params.group + '/users/' + this.state.deleteUserId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroupUsers()
          this.closeDeleteGroupUserModal()
        } else {
          this.setState({error: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  createGroupUser = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/' + this.props.params.namespace + '/groups/' + this.props.params.group + '/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify({
        user: this.state.selectedUser.idusers,
        access: this.state.access
      })
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroupUsers()
          this.closeModal()
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateGroupUser = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/' + this.props.params.namespace + '/groups/' + this.props.params.group + '/users/'+this.state.editUserId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify({
        access: this.state.access
      })
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroupUsers()
          this.closeEditGroupUserModal()
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  openModal = () => {
    this.setState({
      name: '',
      value: '',
      newModalOpen: true,
      error: ''
    })
  }

  closeModal = () => {
    this.setState({
      name: '',
      value: '',
      newModalOpen: false,
      newUserSearch: '',
      newUserSearchResults: [],
      selectedUser: { fullname: '' },
      error: ''
    })
  }

  openEditGroupUserModal = (user: any) => {
    this.setState({
      editModalOpen: true,
      editUserId: user.idusers,
      name: user.fullname,
      access: user.access,
      error: ''
    })
  }

  closeEditGroupUserModal = () => {
    this.setState({
      editModalOpen: false,
      editUserId: '',
      name: '',
      access: 0,
      error: ''
    })
  }

  openDeleteGroupUserModal = (userId: string) => {
    this.setState({
      deleteModalOpen: true,
      deleteUserId: userId,
      error: ''
    })
  }

  closeDeleteGroupUserModal = () => {
    this.setState({
      deleteModalOpen: false,
      deleteUserId: '',
      error: ''
    })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  searchInputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any, () => this.loadGroupUsers())
  }

  searchAddUserChange = (value: any) => {
    if (value !== '') {
      this.setState({ newUserSearch: value } as any, () => this.loadMembers())
    } else {
      this.setState({ newUserSearch: '', newUserSearchResults: [] } as any)
    }
  }

  newGroupUserModal = () => {
    return (
      <Transition.Root show={this.state.newModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => this.closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Group Member
                      </Dialog.Title>
                      <div>
                        <div className="mt-3">
                          <Combobox as="div" value={this.state.selectedUser} onChange={(user: any) => this.setState({ selectedUser: user })}>
                            <Combobox.Label className="text-left block text-sm font-medium leading-6 text-gray-900">Search to add</Combobox.Label>
                            <div className="relative mt-2">
                              <Combobox.Input
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                onChange={(event) => this.searchAddUserChange(event.target.value)}
                                displayValue={(user: any) => user?.fullname}
                              />
                              {this.state.newUserSearchResults.length > 0 && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                                  {this.state.newUserSearchResults.map((user: any) => (
                                    <Combobox.Option
                                      key={user.id}
                                      value={user}
                                      className={({ active }) =>
                                        classNames(
                                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        )
                                      }
                                    >
                                      {({ active, selected }) => (
                                        <>
                                          <span className={classNames('block truncate', selected && 'font-semibold')}>{user.fullname}</span>

                                          {selected && (
                                            <span
                                              className={classNames(
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                active ? 'text-white' : 'text-blue-600'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))}
                                </Combobox.Options>
                              )}
                            </div>
                          </Combobox>
                        </div>
                        {this.state.selectedUser && this.state.selectedUser.fullname !== '' ?
                          <>
                            <div className="mt-3">
                              <label htmlFor="access" className="text-left block text-sm font-medium leading-6 text-gray-900 pt-2 pr-2">
                                Access:
                              </label>
                              <Menu as="span" className="relative inline-block text-left mt-1 w-full">
                                <div>
                                  <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    {accessLevels[this.state.access]}
                                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                                  </MenuButton>
                                </div>
                                <MenuItems
                                  transition
                                  className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white border border-gray-300 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                  <div className="py-1">
                                    {Object.keys(accessLevels).map((key: any)=>
                                      <MenuItem key={key}>
                                        <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={()=>this.setState({access: key})}>
                                          {accessLevels[key]}
                                        </div>
                                      </MenuItem>
                                    )}
                                  </div>
                                </MenuItems>
                              </Menu>
                            </div>
                          </>
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      disabled={this.state.selectedUser && this.state.selectedUser.fullname === ''}
                      className="inline-flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                      onClick={() => this.createGroupUser()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  editGroupUserModal = () => {
    return (
      <Transition.Root show={this.state.editModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => this.closeEditGroupUserModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0" 
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit {this.state.name}
                      </Dialog.Title>
                      <div className="mt-5">
                          <label htmlFor="access" className="text-left block text-sm font-medium leading-6 text-gray-900 pt-2 pr-2">
                            Access:
                          </label>
                          <Menu as="span" className="relative inline-block text-left mt-1 w-full">
                            <div>
                              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {accessLevels[this.state.access]}
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                              </MenuButton>
                            </div>
                            <MenuItems
                              transition
                              className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white border border-gray-300 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                {Object.keys(accessLevels).map((key: any)=>
                                  <MenuItem key={key}>
                                    <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={()=>this.setState({access: key})}>
                                      {accessLevels[key]}
                                    </div>
                                  </MenuItem>
                                )}
                              </div>
                            </MenuItems>
                          </Menu>
                        </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center inline-flex rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                      onClick={() => this.updateGroupUser()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeEditGroupUserModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  deleteGroupUserModal = () => {
    return (
      <Transition.Root show={this.state.deleteUserId !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => this.closeDeleteGroupUserModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => this.closeDeleteGroupUserModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Remove user
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to remove user from this group?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 flex">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => this.closeDeleteGroupUserModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 ml-3"
                      onClick={() => this.deleteGroupUser()}
                    >
                      Remove
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  render() {
    return (
      <SettingsHolder>
        <SEOHeaders title={'Group Users'} appendTitle/>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              Manage members and their access in groups
            </p>
            <div className="mt-4 flex rounded-md">
              <div className="relative flex items-stretch">
                <input
                  id="search"
                  name="search"
                  placeholder="Search group members..."
                  onChange={this.searchInputChange}
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </button>
              <button
                type="button"
                onClick={()=>this.openModal()}
                className="block ml-4 rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
              >
                Add Group Member
              </button>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Member
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Access
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {this.state.users.map((user) => (
                <tr key={user.idusers}>
                  <td className="flex cursor-pointer py-3 text-sm text-gray-500 hover:text-gray-700" onClick={()=>this.openEditGroupUserModal(user)}>
                    {user.fullname} <PencilSquareIcon className="w-4 h-4 mt-0.5 mr-1 ml-1.5"/>
                  </td>
                  <td className="cursor-pointer py-3 text-sm text-gray-500 hover:text-gray-700" onClick={()=>this.openEditGroupUserModal(user)}>
                    {accessLevels[user.access]}
                  </td>
                  <td className="w-6 py-3 text-sm text-gray-500 hover:text-gray-700">
                    <TrashIcon className='w-4 h-4 cursor-pointer' onClick={()=>this.openDeleteGroupUserModal(user.idusers)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {this.state.usersCount > usersPerPage ?
          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
              <Link
                to={'#'}
                onClick={() => this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 }, () => this.loadGroupUsers())}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </Link>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {Array.from(Array(Math.ceil(this.state.usersCount / usersPerPage)).keys()).map((el: number) => {
                return <Link
                  key={el}
                  to={"#"}
                  onClick={() => this.setState({ page: el }, () => this.loadGroupUsers())}
                  className={this.state.page !== el ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" : "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"}
                >
                  {el + 1}
                </Link>
              })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
              <Link
                to={"#"}
                onClick={() => this.setState({ page: this.state.page < Math.ceil(this.state.usersCount / usersPerPage) ? this.state.page + 1 : this.state.page }, () => this.loadGroupUsers())}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Link>
            </div>
          </nav>
          : null}

        {this.newGroupUserModal()}
        {this.editGroupUserModal()}
        {this.deleteGroupUserModal()}

      </SettingsHolder>
    );
  }
}

export default function GroupUsersWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <GroupUsers params={params} navigate={navigate} />
}