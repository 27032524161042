import React, { Component, Fragment } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { Switch, Dialog, Transition, Combobox } from '@headlessui/react'
import ProfileHolder from './ProfileHolder'
import { SEOHeaders } from "./SeoHeaders"
import { CheckIcon as CheckIconSolid, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon, TrashIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Error } from './Error'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface APIsViewProps {
  navigate: any
  params: any
}

type APIsViewStates = {
  completions: any[]
  completionOpen: any
  completionAccessSearch: string
  completionAccessResults: any[]
  completionAccessSelectedResult: any
  completionError: string
  name: string
  access: any[]
  namespaces: any[]
  namespace: any
}

class APIsView extends Component <APIsViewProps, APIsViewStates> {
  constructor (props: APIsViewProps) {
    super(props)
    this.state = {
      completions: [],
      completionOpen: undefined,
      completionAccessSearch: '',
      completionAccessResults: [],
      completionAccessSelectedResult: undefined,
      completionError: '',
      name: '',
      access: [],
      namespaces: [],
      namespace: undefined
    }
  }
  
  componentDidMount(){
    this.loadProfile()
    this.loadCompletions()
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const namespaces = json.data.userMemberships.map((membership: any) => {
            return {
              id: membership.namespace.id,
              name: membership.namespace.name,
            }
          })
          this.setState({
            namespaces,
            namespace: namespaces[0]
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  loadCompletions = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/chat/completions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            completions: json.completions
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  saveCompletion = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          access: this.state.access,
          namespace: this.state.namespace.id
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadCompletions()
          this.setState({
            completionOpen: undefined,
            name: '',
            access: []
          })
        }
        if (json.status === 'error') {
          this.setState({completionError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  deleteCompletion = (id: string) => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/chat/completions/'+id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadCompletions()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.completionAccessSearch) {
      search = '&search=' + this.state.completionAccessSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.state.namespace.id}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            completionAccessResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ completionAccessSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ completionAccessSearch: '', completionAccessResults: [] } as any)
    }
  }
  
  addAccess = (group: any) => {
    if (group === null) return
    const access = this.state.access
    access.push(group)
    this.setState({
      completionAccessSearch: '',
      completionAccessSelectedResult: undefined,
      completionAccessResults: [],
      access
    })
  }

  removeAccess = (id: string) => {
    const access = this.state.access
    const newaccess = access.filter(el => el.id !== id)
    this.setState({
      access: newaccess
    })
  }
  
  openCompletion = (id: string) => {
    if (id !== '') {
      const completion = this.state.completions.find((el: any) => el.id === id)
      this.setState({ completionOpen: completion.id, })
    } else {
      this.setState({ completionOpen: id })
    }
  }
  
  closeCompletion = () => {
    this.setState({
      completionOpen: undefined
    })
  }
  
  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }
  
  completionModal = () => {
    return (
      <Transition.Root show={this.state.completionOpen !== undefined} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => this.closeCompletion()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Create Access
                      </Dialog.Title>
                    </div>
                    <div>
                      <div className="mt-3">
                        <label htmlFor="vshares" className="text-left block text-sm font-medium leading-6 text-gray-900">
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            name="name"
                            id="name"
                            type='text'
                            onChange={this.inputChange}
                            value={this.state.name}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className='mt-3'>
                        <Listbox value={this.state.namespace} onChange={(namespace: any) => this.setState({namespace})}>
                          <Label className="block text-sm font-medium leading-6 text-gray-900">Space</Label>
                          <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">{this.state.namespace?.name}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {this.state.namespaces.map((namespace: any) => (
                                <ListboxOption
                                  key={namespace.id}
                                  value={namespace}
                                  onChange={()=>this.setState({access:[]})}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
                                >
                                  <span className="block truncate font-normal group-data-[selected]:font-semibold">{namespace.name}</span>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIconSolid aria-hidden="true" className="h-5 w-5" />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                      <div className="mt-3">
                        <label htmlFor="vshares" className="text-left block text-sm font-medium leading-6 text-gray-900">
                          Access
                        </label>
                        <div className='flex flex-row pr-4 mt-1'>
                          {this.state.access.map((access: any) => {
                            return <div key={access.id} className='pr-1'>
                              <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                {access.name}
                                <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                                  <span className="sr-only">Remove</span>
                                  <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                  </svg>
                                  <span className="absolute -inset-1" />
                                </button>
                              </span>
                            </div>
                          })}
                          <div>
                            <Combobox as="div" value={this.state.completionAccessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                              <div className="relative">
                                <Combobox.Input
                                  className="rounded-md border-0 bg-white py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                                  onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                                  displayValue={(group: any) => ''}
                                  placeholder='Search to add...'
                                />
                                {this.state.completionAccessResults.length > 0 && (
                                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                                    {this.state.completionAccessResults.map((group: any) => (
                                      <Combobox.Option
                                        key={group.id}
                                        value={group}
                                        className={({ active }) =>
                                          classNames(
                                            'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                            {selected && (
                                              <span
                                                className={classNames(
                                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                                  active ? 'text-white' : 'text-blue-600'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </div>
                            </Combobox>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center cursor-pointer rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                          onClick={() => this.saveCompletion()}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => this.closeCompletion()}
                        >
                          Cancel
                        </button>
                      </div>
                      <Error logError={this.state.completionError} setLogError={(e:string)=>this.setState({completionError:e})} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  
  render () {
    return (
      <ProfileHolder>
        <SEOHeaders title={'Agents'} appendTitle/>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700 pb-4">
              Setup external access for specialised tools. Requests will be enriched with information from pages and executed.
            </p>
            <p className="text-sm text-gray-700">
              <span className='font-semibold'>URL: </span>{`https://backend.vortn.com/api/v1/chat/completions/{ACCESS-ID}`}
            </p>
            <p className="text-sm text-gray-700">
              <span className='font-semibold'>Header: </span>{`Authorization: Bearer {ACCESS-KEY}`}
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <button
            type="button"
            onClick={()=>this.openCompletion('')}
            className="block rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
          >
            New Access
          </button>
        </div>

        <div className="mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Access ID
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Access Key
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {this.state.completions.map((completion) => (
                <tr>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 sm:pl-0">
                    {completion.name}
                  </td>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                    <div className="flex items-center">{completion.id} <CopyToClipboard text={completion.id}><ClipboardDocumentIcon className='w-4 h-4 cursor-pointer ml-2'/></CopyToClipboard></div>
                  </td>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                    <div className="flex items-center">{completion.key.slice(0, 21)}... <CopyToClipboard text={completion.key}><ClipboardDocumentIcon className='w-4 h-4 cursor-pointer ml-2'/></CopyToClipboard></div>
                  </td>
                  <td className="whitespace-nowrap w-6 py-4 text-sm text-gray-500 cursor-pointer" onClick={()=>this.deleteCompletion(completion.id)}>
                    <TrashIcon className='w-4 h-4' />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {this.completionModal()}

      </ProfileHolder>
    );
  }
}

export default function APIsViewWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <APIsView params={params} navigate={navigate}/>
}