import { useCallback, useMemo, useState } from "react";
import { useTTSWorker } from "./useTTSWorker";

export interface Voicer {
    ready: boolean
    processing: boolean
    toprocess: string[]
    processed: any[]
}

export function useVoicer(): Voicer {
    const [ready, setReady] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [toprocess, setToprocess] = useState([] as any[])
    const [processed, setProcessed] = useState([] as any[])

    const webWorker = useTTSWorker((event) => {
        switch (event.data.status) {
            case 'loaded':
                setReady(true);
            break;
            case 'complete':
                // add to processed
                setProcessed((prevProcessed) => {
                    const processedFresh = [...prevProcessed]
                    processedFresh.push(URL.createObjectURL(event.data.output) as any)
                    return processedFresh
                });
                // check if more to process
                setToprocess((prevToprocess) => {
                    if (prevToprocess.length > 0) {
                        const toprocessFresh = [...prevToprocess]
                        const text = toprocessFresh.shift()
                        webWorker.postMessage({ text })
                        return toprocessFresh
                    } else {
                        setProcessing(false)
                        return prevToprocess
                    }
                });
            break;
        }
    })

    const shift = useCallback(
        async () => {
            const processedFresh = [...processed]
            processedFresh.shift()
            setProcessed(processedFresh)
        },
        [processed],
    )

    const process = useCallback(
        async (text: string) => {
            if (processing === false) {
                webWorker.postMessage({text})
                setProcessing(true)
            } else {
                const toprocessFresh = [...toprocess]
                toprocessFresh.push(text)
                setToprocess(toprocessFresh)
            }
        },
        [webWorker, processing, toprocess],
    )

    const load = useCallback(
        async () => {
            webWorker.postMessage({load: true})
        },
        [webWorker],
    )

    const voicer = useMemo(() => {
        return {
            ready,
            processing,
            process,
            load,
            shift,
            toprocess,
            processed
        };
    }, [
        ready,
        processing,
        process,
        load,
        shift,
        toprocess,
        processed
    ]);

    return voicer
}
