import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import './index.scss';

import Landing from './Landing';
import Loader from './Loader';
import Signin from './Signin';
import Signup from './Signup';
import Forgot from './Forgot';
import Reset from './Reset';
import NewEditDashboard from './NewEditDashboard';
import PageIndex from './PageIndex';
import ChatView from './ChatView';
import Agents from './Agents';
import AgentView from './AgentView';
import AgentViewRun from './AgentViewRun';
import FeedView from './FeedView';
import Profile from './Profile';
import Groups from './Groups';
import Members from './Members';
import APIsView from './APIsView';
import Settings from './Settings';
import GroupUsers from './GroupUsers';
import Billings from './Billings';
import Password from './Password';
import MemberInvites from './MemberInvites';
import VerifyEmail from './VerifyEmail';

const router = createBrowserRouter([{
  path: "/",
  element: <Landing/>
},{
  path: "/new",
  element: <NewEditDashboard/>
},{
  path: "/d/",
  element: <Loader/>
},{
  path: "/d/:namespace/p/:page?",
  element: <PageIndex/>
},{
  path: "/d/:namespace/edit",
  element: <NewEditDashboard/>
},{
  path: "/d/:namespace/c/:thread?",
  element: <ChatView/>
},{
  path: "/d/:namespace/a",
  element: <Agents/>
},{
  path: "/d/:namespace/a/new",
  element: <AgentView/>
},{
  path: "/d/:namespace/a/:agent",
  element: <AgentView/>
},{
  path: "/d/:namespace/a/:agent/:run",
  element: <AgentViewRun/>
},{
  path: "/d/:namespace/f",
  element: <FeedView/>
},{
  path: "/d/:namespace/o",
  element: <Settings/>
},{
  path: "/d/:namespace/g",
  element: <Groups/>
},{
  path: "/d/:namespace/g/:group/users",
  element: <GroupUsers/>
},{
  path: "/d/:namespace/m",
  element: <Members/>
},{
  path: "/d/:namespace/b",
  element: <Billings/>
},{
  path: "/signin",
  element: <Signin/>
},{
  path: "/signup",
  element: <Signup/>
},{
  path: "/forgot",
  element: <Forgot/>
},{
  path: "/reset",
  element: <Reset/>
}, {
  path: "/profile",
  element: <Profile/>
}, {
  path: "/password",
  element: <Password/>
}, {
  path: "/verifyemail/:token",
  element: <VerifyEmail/>
}, {
  path: "/apis",
  element: <APIsView/>
}, {
  path: "/memberinvites",
  element: <MemberInvites/>
}]);

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement);
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
