import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'

interface LoaderProps {
  navigate: any
  params: any
}

type LoaderStates = {

}

class Loader extends Component <LoaderProps, LoaderStates> {
  constructor (props: LoaderProps) {
    super(props)
    this.state = {
      
    }
  }

  componentDidMount(){
    this.loadNamespaces()
  }

  loadNamespaces = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          if (json.namespaces.length > 0) {
            this.props.navigate('/d/'+json.namespaces[0].id+'/c')
          } else {
            this.props.navigate('/e')
          }
        } else {
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <div></div>
    );
  }
}

export default function LoaderWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Loader params={params} navigate={navigate}/>
 }