import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import DashboardHolder from './DashboardHolder'
import {  Switch, Combobox, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  LockClosedIcon,
  ClockIcon,
  CheckIcon,
  RocketLaunchIcon,
  CircleStackIcon,
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
  CheckCircleIcon,
  Cog8ToothIcon,
  LockOpenIcon,
  UsersIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import { Cron } from 'react-js-cron'
import Moment from 'react-moment'
import { SEOHeaders } from "./SeoHeaders"
import { ErrorNotification } from './ErrorNotification'

const accessLevels: any = {
  '50': {desc: '(group can edit)', short: 'Edit'},
  '0': {desc: '(group can view)', short: 'View'},
  '-1': {desc: '(group user access)', short: 'Inherit'}
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const runsPerPage = 25

interface AgentViewProps {
  navigate: any
  params: any
}

type AgentViewStates = {
  name: string
  prompt: string
  access: any[]
  ownerships: any[]
  runs: any[]
  page: number
  runsCount: number
  cron: any
  agentRunError: string
  saveAgentError: string
  addAccessAgentSearch: string
  addAccessSelectedResult: any
  addAccessAgentResults: any[]
  addOwnershipAgentSearch: string
  addOwnershipSelectedResult: any
  addOwnershipAgentResults: any[]
}

class AgentView extends Component <AgentViewProps, AgentViewStates> {
  loadInterval: any = undefined
  constructor (props: AgentViewProps) {
    super(props)
    this.state = {
      name: '',
      prompt: '',
      access: [],
      ownerships: [],
      runs: [],
      page: 0,
      runsCount: 0,
      cron: null,
      agentRunError: '',
      saveAgentError: '',
      addAccessAgentSearch: '',
      addAccessSelectedResult: undefined,
      addAccessAgentResults: [],
      addOwnershipAgentSearch: '',
      addOwnershipSelectedResult: undefined,
      addOwnershipAgentResults: []
    }
  }

  componentDidMount(){
    if (this.props.params.agent) {
      this.loadAgent()
    } else {
      this.loadAllGroups()
    }
    this.startRunInterval()
  }
  
  componentWillUnmount() {
    if (this.loadInterval) {
      clearInterval(this.loadInterval)
    }
  }

  startRunInterval = () => {
    if (this.loadInterval === undefined) {
      this.loadInterval = setInterval(()=>{
        if (this.props.params.agent) {
          this.loadRuns()
        }
      }, 5000)
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AgentViewStates)
  }

  loadAgent = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents/'+this.props.params.agent, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            name: json.agent.name,
            access: json.agent.access,
            ownerships: json.agent.ownership,
            prompt: json.agent.prompt,
            cron: json.agent.cron
          })
          this.loadRuns()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadRuns = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents/'+this.props.params.agent+'/runs?offset='+this.state.page*runsPerPage+'&limit='+runsPerPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            runs: json.runs,
            runsCount: json.count
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  saveAgent = () => {
    const isNewPrompt = this.props.params.agent ? '/'+this.props.params.agent : ''
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents'+isNewPrompt, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          prompt: this.state.prompt,
          access: this.state.access,
          ownership: this.state.ownerships,
          cron: this.state.cron ? this.state.cron : null
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          if (this.props.params.agent === undefined) {
            this.props.navigate('/d/'+this.props.params.namespace+'/a/'+json.id)
          }
        }
        if (json.status === 'error') {
          this.setState({saveAgentError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  runAgent = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/agents/'+this.props.params.agent+'/runs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadRuns()
        }
        if (json.status === 'error') {
          this.setState({agentRunError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ addAccessAgentSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ addAccessAgentSearch: '', addAccessAgentResults: [] } as any)
    }
  }

  addAccess = (group: any) => {
    if (group === null) return
    const access = this.state.access
    access.push(group)
    this.setState({
      addAccessAgentSearch: '',
      addAccessSelectedResult: undefined,
      addAccessAgentResults: [],
      access
    })
  }

  removeAccess = (id: string) => {
    const access = this.state.access
    const newaccess = access.filter(el => el.id !== id)
    this.setState({
      access: newaccess
    })
  }

  searchOwnershipGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ addOwnershipAgentSearch: value } as any, () => this.loadOwnerships())
    } else {
      this.setState({ addOwnershipAgentSearch: '', addOwnershipAgentResults: [] } as any)
    }
  }
 
  setOwnershipAccessLevel = async (ownershipId: string, level: string) => {
    const ownerships = this.state.ownerships
    const ownership = ownerships.find(acc => acc.id === ownershipId)
    if (ownership) ownership.access = level
    this.setState({ownerships})
  }
  
  addOwnership = (group: any) => {
    if (group === null) return
    const ownerships = this.state.ownerships
    ownerships.push({...group, access: -1})
    this.setState({
      addOwnershipAgentSearch: '',
      addOwnershipSelectedResult: undefined,
      addOwnershipAgentResults: [],
      ownerships
    })
  }

  removeOwnership = (id: string) => {
    const ownerships = this.state.ownerships
    const newaccess = ownerships.filter(el => el.id !== id)
    this.setState({
      ownerships: newaccess
    })
  }
  
  loadOwnerships = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.addOwnershipAgentSearch) {
      search = '&search=' + this.state.addOwnershipAgentSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            addOwnershipAgentResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.addAccessAgentSearch) {
      search = '&search=' + this.state.addAccessAgentSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            addAccessAgentResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadAllGroups = () => {
    const authToken = getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0&access=100`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            access: json.groups,
            ownerships: json.groups.map((el:any)=>{
              return {
                id: el.id,
                access: -1,
                name: el.name
              }
            })
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <DashboardHolder>
        <div className='h-screen flex flex-col'>
          <SEOHeaders title={'Agent "'+this.state.name+'"'} appendTitle/>
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
            
            <div className="lg:flex lg:items-center lg:justify-between pb-4">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/d/'+this.props.params.namespace+'/a'} className="font-medium text-gray-700 hover:text-gray-900">
                          Agents
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-500" aria-hidden="true" />
                        <input
                          name="name"
                          className="block ml-4 rounded-md border-0 py-1.5 -my-1.5 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-blue-600 font-medium"
                          placeholder="Agent Name"
                          value={this.state.name}
                          onChange={this.inputChange}
                        />
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className='flex flex-col mt-1'>
                  
                  <div className='flex flex-row flex-wrap mt-3'>
                    <div>
                      <UsersIcon className="mr-1.5 mt-2.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    </div>
                    {this.state.ownerships.map((ownership: any) => {
                      return <div key={ownership.id} className='pr-1 mt-1'>
                        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          {ownership.name}
                          <Menu as="div" className="relative inline-block text-left ml-1">
                            <div>
                              <MenuButton className="flex items-center rounded-sm hover:bg-red-600/20 text-red-600/50 hover:text-red-600/75 focus:outline-none">
                                <LockClosedIcon aria-hidden="true" className="h-3 w-3" />
                                {accessLevels[ownership.access].short}
                              </MenuButton>
                            </div>
                            <MenuItems
                              transition
                              className="absolute left-0 top-5 z-10 mt-2 w-56 origin-top-left rounded-sm bg-white ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                {Object.keys(accessLevels).map((key: any)=>
                                  <MenuItem key={key}>
                                    <div
                                      className='block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer'
                                      onClick={()=>this.setOwnershipAccessLevel(ownership.id, key)}
                                    >
                                      {accessLevels[key].short} <span className="text-sm text-gray-500">{accessLevels[key].desc}</span>
                                    </div>
                                  </MenuItem>
                                )}
                              </div>
                            </MenuItems>
                          </Menu>
                          <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeOwnership(ownership.id)}>
                            <span className="sr-only">Remove</span>
                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                              <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span className="absolute -inset-1" />
                          </button>
                        </span>
                      </div>
                    })}
                    <div>
                      <Combobox as="div" value={this.state.addOwnershipSelectedResult} onChange={(group: any) => this.addOwnership(group)} >
                        <div className="relative">
                          <Combobox.Input
                            className="rounded-md mt-1 border-0 bg-white py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                            onChange={(event) => this.searchOwnershipGroupChange(event.target.value)}
                            displayValue={(group: any) => ''}
                            placeholder='Set owners...'
                          />
                          {this.state.addOwnershipAgentResults.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                              {this.state.addOwnershipAgentResults.map((group: any) => (
                                <Combobox.Option
                                  key={group.id}
                                  value={group}
                                  className={({ active }) =>
                                    classNames(
                                      'cursor-pointer relative select-none py-2 pl-3 pr-9',
                                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    )
                                  }
                                >
                                  {({ active, selected }) => (
                                    <>
                                      <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                      {selected && (
                                        <span
                                          className={classNames(
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                            active ? 'text-white' : 'text-blue-600'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </Combobox.Options>
                          )}
                        </div>
                      </Combobox>
                    </div>
                  </div>
                  
                  <div className='flex flex-row mt-2'>
                    <div>
                      <ClockIcon className="mr-1.5 mt-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    </div>
                    <div>
                      <Switch
                        checked={this.state.cron !== null}
                        onChange={() => {
                          if (this.state.cron !== null) {
                            this.setState({cron: null})
                          } else {
                            this.setState({cron: '0 * * * *'})
                          }
                        }}
                        className={classNames(
                          this.state.cron !== null ? 'bg-blue-600' : 'bg-gray-200',
                          'relative inline-flex mt-1 mr-1 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.cron !== null ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-0 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                    { this.state.cron !== null ?
                      <div>
                        <Cron
                          value={this.state.cron}
                          setValue={(value: any) => {
                            this.setState({cron: value})
                          }}
                          allowedDropdowns={[
                            'period',
                            'months',
                            'month-days',
                            'week-days',
                            'hours'
                          ]}
                          allowedPeriods={[
                            'month',
                            'week',
                            'day',
                            'hour'
                          ]}
                          leadingZero={false}
                          clearButton={false}
                          // allowEmpty={false}
                          // displayError={displayErrorStyle}
                          // clearButton={displayClearButton}
                          // clearButtonAction={clearButtonAction}
                          // onError={onError}
                        />
                      </div>
                    : null }
                  </div>
                  
                  <div className='flex flex-row flex-wrap mt-1'>
                    <div>
                      <LockOpenIcon className="mr-1.5 mt-2.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    </div>
                    {this.state.access.map((access: any) => {
                      return <div key={access.id} className='pr-1 mt-1'>
                        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          {access.name}
                          <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                            <span className="sr-only">Remove</span>
                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                              <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span className="absolute -inset-1" />
                          </button>
                        </span>
                      </div>
                    })}
                    <div>
                      <Combobox as="div" value={this.state.addAccessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                        <div className="relative">
                          <Combobox.Input
                            className="rounded-md border-0 mt-1 bg-white py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                            onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                            displayValue={(group: any) => ''}
                            placeholder='Access pages as...'
                          />
                          {this.state.addAccessAgentResults.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                              {this.state.addAccessAgentResults.map((group: any) => (
                                <Combobox.Option
                                  key={group.id}
                                  value={group}
                                  className={({ active }) =>
                                    classNames(
                                      'cursor-pointer relative select-none py-2 pl-3 pr-9',
                                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    )
                                  }
                                >
                                  {({ active, selected }) => (
                                    <>
                                      <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                      {selected && (
                                        <span
                                          className={classNames(
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                            active ? 'text-white' : 'text-blue-600'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </Combobox.Options>
                          )}
                        </div>
                      </Combobox>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div className="mt-5 flex lg:ml-4 lg:mt-0">
                <span className="">
                  <button
                    onClick={()=>this.saveAgent()}
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <CircleStackIcon className="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                    Save
                  </button>
                </span>
                <span className="ml-3">
                  <button
                    onClick={()=>this.runAgent()}
                    type="button"
                    className="inline-flex rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                  >
                    <RocketLaunchIcon className="-ml-0.5 mr-1.5 mt-0.5 h-4 w-4 text-blue-900" aria-hidden="true" />
                    Run
                  </button>
                </span>
              </div>
            </div>
            <textarea
              name="prompt"
              rows={10}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={this.state.prompt}
              placeholder='Agent directives and orders...'
              onChange={this.inputChange}
            />
          </div>

          <div className="flex flex-col overflow-y-auto flex-1 px-4 sm:px-6 lg:px-8 pt-1 pb-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Run
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {this.state.runs.map((run) => (
                  <tr key={run.id}>
                    <td className="whitespace-nowrap font-mono py-3 pl-4 pr-3 text-sm font-medium text-gray-500 hover:text-gray-700 sm:pl-0">
                      <Link to={'/d/'+this.props.params.namespace+'/a/'+this.props.params.agent+'/'+run.id}>#{run.id}</Link>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                      {run.status === 'working' ?
                        <div className="flex flex-row">
                          <Cog8ToothIcon className="w-5 h-5 mr-2 animate-spin" />
                          Working
                        </div>
                      : ''}
                      {run.status === 'completed' ?
                        <div className="flex flex-row">
                          <CheckCircleIcon className="w-5 h-5 mr-2" />
                          Completed
                        </div>
                      : ''}
                    </td>
                    <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                      <Moment format="YYYY/MM/DD HH:mm:ss">
                        {run.createdAt}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {this.state.runsCount > runsPerPage ?
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1">
                <Link
                  to={'#'}
                  onClick={()=>this.setState({page: this.state.page > 0 ? this.state.page-1 : 0},()=>this.loadRuns())}
                  className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Previous
                </Link>
              </div>
              <div className="hidden md:-mt-px md:flex">
                {Array.from(Array(Math.ceil(this.state.runsCount/runsPerPage)).keys()).map((el: number) => {
                  return <Link
                    key={el}
                    to={"#"}
                    onClick={()=>this.setState({page: el},()=>this.loadRuns())}
                    className={this.state.page !== el ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" : "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"}
                  >
                    {el+1}
                  </Link>
                })}
              </div>
              <div className="-mt-px flex w-0 flex-1 justify-end">
                <Link
                  to={"#"}
                  onClick={()=>this.setState({page: this.state.page < Math.ceil(this.state.runsCount / runsPerPage) ? this.state.page+1 : this.state.page},()=>this.loadRuns())}
                  className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  Next
                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Link>
              </div>
            </nav>
          : null}
          
        </div>
        
        <ErrorNotification error={this.state.agentRunError} setError={()=>this.setState({agentRunError: ''})} />
        <ErrorNotification error={this.state.saveAgentError} setError={()=>this.setState({saveAgentError: ''})} />
        
      </DashboardHolder>
    );
  }
}

export default function AgentViewWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <AgentView params={params} navigate={navigate} />
}