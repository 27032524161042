import React, { Fragment, Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import SettingsHolder from './SettingsHolder'
import { Dialog, Transition } from '@headlessui/react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon, ChevronRightIcon, PlusIcon, XMarkIcon, Bars3Icon, ExclamationTriangleIcon, PlusCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { SEOHeaders } from "./SeoHeaders"
import {
  TrashIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { Error } from './Error'
import { Success } from './Success'

const membersPerPage = 25

const accessLevels: any = {
  '0': 'Member',
  '100': 'Admin'
}

interface MembersProps {
  navigate: any
  params: any
}

type MembersStates = {
  members: any[]
  membersCount: number
  search: string
  page: number
  email: string
  newModalOpen: boolean
  newModalIsLoading: boolean
  newModalSuccess: string
  deleteModalOpen: boolean
  deleteMemberId: string
  editModalOpen: boolean,
  editMemberId: string,
  error: string
  access: number
}

class Members extends Component <MembersProps, MembersStates> {
  constructor (props: MembersProps) {
    super(props)
    this.state = {
      members: [],
      membersCount: 0,
      search: '',
      page: 0,
      email: '',
      newModalOpen: false,
      newModalIsLoading: false,
      newModalSuccess: '',
      deleteModalOpen: false,
      deleteMemberId: '',
      editModalOpen: false,
      editMemberId: '',
      error: '',
      access: 0
    }
  }

  componentDidMount(){
    this.loadMembers()
  }
  
  loadMembers = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.search) {
      search = '&search='+this.state.search
    }
    fetch(
      `${config.app.apiUri}/api/v1/namespace/${this.props.params.namespace}/members?limit=${membersPerPage}&offset=${this.state.page*membersPerPage}${search}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            members: json.members,
            membersCount: json.count
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteMember = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/members/'+this.state.deleteMemberId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadMembers()
          this.closeDeleteMemberModal()
        } else {
          this.setState({error: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inviteMember = () => {
    this.setState({newModalIsLoading: true, error: '', newModalSuccess: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/members/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          email: this.state.email
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({newModalIsLoading: false, newModalSuccess: json.message})
          this.loadMembers()
        } else {
          this.setState({
            error: json.message,
            newModalIsLoading: false,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateMember = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/members/'+this.state.editMemberId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          access: this.state.access
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadMembers()
          this.closeEditModal()
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  openModal = () => {
    this.setState({
      email: '',
      access: 0,
      newModalOpen: true,
      error: ''
    })
  }

  closeModal = () => {
    this.setState({
      email: '',
      newModalOpen: false,
      error: '',
      newModalSuccess:''
    })
  }

  closeDeleteMemberModal = () => {
    this.setState({
      deleteModalOpen: false,
      deleteMemberId: '',
      error: ''
    })
  }

  openDeleteMemberModal = (memberId: string) => {
    this.setState({
      deleteModalOpen: true,
      deleteMemberId: memberId,
      error: ''
    })
  }

  openEditModal = (member: any) => {
    this.setState({
      editMemberId: member.idusers,
      access: member.access,
      editModalOpen: true,
      error: ''
    })
  }

  closeEditModal = () => {
    this.setState({
      editModalOpen: false,
      editMemberId: '',
      access: 0,
      error: ''
    })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  searchInputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any, () => this.loadMembers())
  }

  editMemberModal = () => {
    return (
      <Transition.Root show={this.state.editModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Member
                      </Dialog.Title>
                      <div>
                        <div className="mt-5">
                          <label htmlFor="access" className="text-left block text-sm font-medium leading-6 text-gray-900 pt-2 pr-2">
                            Access:
                          </label>
                          <Menu as="span" className="relative inline-block text-left mt-1 w-full">
                            <div>
                              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {accessLevels[this.state.access]}
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                              </MenuButton>
                            </div>
                            <MenuItems
                              transition
                              className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white border border-gray-300 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                {Object.keys(accessLevels).map((key: any)=>
                                  <MenuItem key={key}>
                                    <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={()=>this.setState({access: key})}>
                                      {accessLevels[key]}
                                    </div>
                                  </MenuItem>
                                )}
                              </div>
                            </MenuItems>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center inline-flex rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                      onClick={() => this.updateMember()}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeEditModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  newMemberModal = () => {
    return (
      <Transition.Root show={this.state.newModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Member
                      </Dialog.Title>
                      <div>
                        <div className="rounded-md bg-yellow-50 p-4 mt-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                            </div>
                            <div className="ml-3">
                              <div className="text-sm text-yellow-700">
                                <p>
                                  Having more then 5 members in the space will move it into membership mode with a charge of $9 member/month.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="email" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Invite Email
                          </label>
                          <div className="mt-1">
                            <input
                              name="email"
                              id="email"
                              onChange={this.inputChange}
                              value={this.state.email}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder="member@example.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {!this.state.newModalIsLoading ?
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                        onClick={() => this.inviteMember()}
                      >
                        Send Invite
                      </button>
                    :
                      <button
                        type="button"
                        disabled
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                      >
                        Sending...
                      </button>
                    }
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Success logSuccess={this.state.newModalSuccess} setLogSuccess={(e:string)=>this.closeModal()} />
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  deleteMemberModal = () => {
    return (
      <Transition.Root show={this.state.deleteMemberId !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeDeleteMemberModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={()=>this.closeDeleteMemberModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Remove Member
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to remove a member?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 flex">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={()=>this.closeDeleteMemberModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 ml-3"
                      onClick={()=>this.deleteMember()}
                    >
                      Remove
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                  <Success logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  render () {
    return (
      <SettingsHolder>

        <SEOHeaders title={'Space Members'} appendTitle/>

        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              Add members to your space and assign global access
            </p>
            <div className="mt-4 flex rounded-md">
              <div className="relative flex items-stretch">
                <input
                  id="search"
                  name="search"
                  placeholder="Search members..."
                  onChange={this.searchInputChange}
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </button>
              <button
                type="button"
                onClick={()=>this.openModal()}
                className="block rounded-md ml-4 bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
              >
                Add Member
              </button>
            </div>
          </div>
        </div>
        
        <div className="mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Member
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Access
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {this.state.members.map((member) => (
                <tr key={member.id}>
                  <td className="py-3 text-sm text-gray-500 hover:text-gray-700">
                    <div className='cursor-pointer flex' onClick={()=>this.openEditModal(member)}>
                      {member.fullname} <PencilSquareIcon className="w-4 h-4 mt-0.5 mr-1 ml-1.5"/>
                    </div>
                  </td>
                  <td className="py-3 text-sm text-gray-500 cursor-pointer" onClick={()=>this.openEditModal(member)}>
                    {accessLevels[member.access]}
                  </td>
                  <td className="w-6 py-3 text-sm text-gray-500 hover:text-gray-700">
                    <TrashIcon className='w-4 h-4 cursor-pointer' onClick={()=>this.openDeleteMemberModal(member.id)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {this.state.membersCount > membersPerPage ?
          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
              <Link
                to={'#'}
                onClick={()=>this.setState({page: this.state.page > 0 ? this.state.page-1 : 0},()=>this.loadMembers())}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </Link>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {Array.from(Array(Math.ceil(this.state.membersCount/membersPerPage)).keys()).map((el: number) => {
                return <Link
                  key={el}
                  to={"#"}
                  onClick={()=>this.setState({page: el},()=>this.loadMembers())}
                  className={this.state.page !== el ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" : "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"}
                >
                  {el+1}
                </Link>
              })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
              <Link
                to={"#"}
                onClick={()=>this.setState({page: this.state.page < Math.ceil(this.state.membersCount / membersPerPage) ? this.state.page+1 : this.state.page},()=>this.loadMembers())}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Link>
            </div>
          </nav>
        : null}

        {this.newMemberModal()}
        {this.editMemberModal()}
        {this.deleteMemberModal()}

      </SettingsHolder>
    );
  }
}

export default function MembersWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Members params={params} navigate={navigate}/>
}