import React, { Component, Fragment } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { RadioGroup, Dialog, Transition  } from '@headlessui/react'
import SettingsHolder from './SettingsHolder'
import { SEOHeaders } from "./SeoHeaders"
import { ErrorNotification } from './ErrorNotification'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const plans = [
  // { id: 'basicmistral', name: 'Mistral Nemo', desc: 'Enterprise-grade small model from Mistral.ai with affordable pricing', inputPrice: '$0.3', outputPrice: '$0.3'},
  // { id: 'promistral', name: 'Mistral Large', desc: 'Top-tier reasoning model for high-complexity tasks from Mistral.ai', inputPrice: '$4', outputPrice: '$12'},
  { id: 'basicopenai', name: 'OpenAI GPT-4o-mini', desc: 'GPT-4o mini is cost-efficient small model from OpenAI.com', inputPrice: '$0.3', outputPrice: '$1.2'},
  { id: 'proopenai', name: 'OpenAI GPT-4o', desc: 'GPT-4o is most advanced model from OpenAi.com', inputPrice: '$5', outputPrice: '$20'}
]

interface SettingsProps {
  navigate: any
  params: any
}

type SettingsStates = {
  name: string
  intro: string
  protokens: number
  basictokens: number
  selected: any
  openReloadType: string
  reloadmin: any,
  reloadamount: any
  error: string
}

class Settings extends Component <SettingsProps, SettingsStates> {
  constructor (props: SettingsProps) {
    super(props)
    this.state = {
      name: '',
      intro: '',
      protokens: 0,
      basictokens: 0,
      selected: undefined,
      openReloadType: '',
      reloadmin: '100',
      reloadamount: '1000',
      error: ''
    }
  }

  componentDidMount(){
    this.loadNamespaces()
  }

  loadNamespaces = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/' + this.props.params.namespace, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            name: json.namespace.name,
            intro: json.namespace.description,
            protokens: json.namespace.protokens,
            basictokens: json.namespace.basictokens,
            selected: plans.find((plan: any)=>plan.id === json.namespace.selectedai),
            reloadmin: json.namespace.reloadmin,
            reloadamount: json.namespace.reloadamount
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateNamespace = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/' + this.props.params.namespace, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.intro,
          selectedai: this.state.selected && this.state.selected.id ? this.state.selected.id : 'basic',
          reloadmin: this.state.reloadmin,
          reloadamount: this.state.reloadamount
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          // done
        }
        if (json.status === 'error') {
          this.setState({error: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  render () {
    return (
      <SettingsHolder>
        <SEOHeaders title={'Space Settings'} appendTitle/>
        <div className="sm:col-span-4">
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            Name
          </label>
          <div className="mt-2">
            <input
              id="name"
              name="name"
              value={this.state.name}
              onChange={this.inputChange}
              className="block rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
            About
          </label>
          <div className="mt-2">
            <textarea
              id="intro"
              name="intro"
              rows={3}
              value={this.state.intro}
              onChange={this.inputChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
            AI Engine
          </label>
          <div className="mt-2">
            <fieldset aria-label="Pricing plans">
              <RadioGroup value={this.state.selected} onChange={(selected)=>this.setState({selected})} className="relative -space-y-px rounded-md bg-white">
                {plans.map((plan: any, planIdx: any) => (
                  <RadioGroup.Option
                    key={plan.name}
                    value={plan}
                    className={({ checked }: any) =>
                      classNames(
                        planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                        checked ? 'z-10 border-blue-200 bg-blue-50' : 'border-gray-200',
                        'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6'
                      )
                    }
                  >
                    {({ focus, checked }: any) => (
                      <>
                        <span className="flex items-center text-sm">
                          <span
                            className={classNames(
                              checked ? 'border-transparent bg-blue-600' : 'border-gray-300 bg-white',
                              focus ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                              'flex h-4 w-4 items-center justify-center rounded-full border shrink-0'
                            )}
                            aria-hidden="true"
                          >
                            <span className="h-1.5 w-1.5 rounded-full bg-white" />
                          </span>
                          <span className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'ml-3 font-medium')}>
                            {plan.name}
                          </span>
                        </span>
                        <span
                          className={classNames(
                            checked ? 'text-blue-700' : 'text-gray-500',
                            'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-left'
                          )}
                        >
                          {plan.desc}
                        </span>
                        <div className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                          <div>
                            <span className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'font-medium')}>{plan.inputPrice}</span>
                            <span className={checked ? 'text-blue-700' : 'text-gray-500'}> / 1M input tokens</span>
                          </div>
                          <div>
                            <span className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'font-medium')}>{plan.outputPrice}</span>
                            <span className={checked ? 'text-blue-700' : 'text-gray-500'}> / 1M output tokens</span>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>

        <div className="mt-6">
          <button onClick={()=>this.updateNamespace()} type="button" className="rounded-md bg-blue-100 px-10 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
            Save
          </button>
        </div>
        
        <ErrorNotification error={this.state.error} setError={()=>this.setState({error: ''})}/>

      </SettingsHolder>
    );
  }
}

export default function SettingsWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Settings params={params} navigate={navigate}/>
}