import React, { Fragment, Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import SettingsHolder from './SettingsHolder'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronRightIcon, PlusIcon, XMarkIcon, Bars3Icon, ExclamationTriangleIcon, PlusCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { SEOHeaders } from "./SeoHeaders"
import {
  TrashIcon,
  UsersIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { Error } from './Error'

const groupsPerPage = 25

interface GroupsProps {
  navigate: any
  params: any
}

type GroupsStates = {
  groups: any[]
  groupsCount: number
  search: string
  page: number
  name: string
  value: string
  newModalOpen: boolean
  editModal: string
  deleteModalOpen: boolean
  deleteGroupId: string
  error: string
  description: string
}

class Groups extends Component <GroupsProps, GroupsStates> {
  constructor (props: GroupsProps) {
    super(props)
    this.state = {
      groups: [],
      groupsCount: 0,
      search: '',
      page: 0,
      name: '',
      value: '',
      newModalOpen: false,
      editModal: '',
      deleteModalOpen: false,
      deleteGroupId: '',
      error: '',
      description: ''
    }
  }

  componentDidMount(){
    this.loadGroups()
  }
  
  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.search) {
      search = '&search='+this.state.search
    }
    fetch(
      `${config.app.apiUri}/api/v1/namespace/${this.props.params.namespace}/groups?type=nonmember&limit=${groupsPerPage}&offset=${this.state.page*groupsPerPage}${search}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            groups: json.groups,
            groupsCount: json.count
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteGroup = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/groups/'+this.state.deleteGroupId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroups()
          this.closeDeleteGroupModal()
        } else {
          this.setState({error: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  createGroup = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/groups/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.description
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroups()
          this.closeModal()
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateGroup = () => {
    this.setState({error: ''})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/groups/'+this.state.editModal, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.description
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadGroups()
          this.closeEditModal()
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  openModal = () => {
    this.setState({
      name: '',
      description: '',
      value: '',
      newModalOpen: true,
      error: ''
    })
  }

  closeModal = () => {
    this.setState({
      name: '',
      description: '',
      value: '',
      newModalOpen: false,
      error: ''
    })
  }

  openEditModal = (group: any) => {
    this.setState({
      editModal: group.id,
      name: group.name,
      value: group.value,
      description: group.description,
      error: ''
    })
  }

  closeEditModal = () => {
    this.setState({
      editModal: '',
      name: '',
      value: '',
      description: '',
      error: ''
    })
  }

  closeDeleteGroupModal = () => {
    this.setState({
      deleteModalOpen: false,
      deleteGroupId: '',
      error: ''
    })
  }

  openDeleteGroupModal = (groupId: string) => {
    this.setState({
      deleteModalOpen: true,
      deleteGroupId: groupId,
      error: ''
    })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  searchInputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any, () => this.loadGroups())
  }

  newGroupModal = () => {
    return (
      <Transition.Root show={this.state.newModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        New Group
                      </Dialog.Title>
                      <div>
                        <div className="mt-3">
                          <label htmlFor="name" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Name of Group
                          </label>
                          <div className="mt-1">
                            <input
                              name="name"
                              id="name"
                              onChange={this.inputChange}
                              value={this.state.name}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="description" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Group Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={3}
                              name="description"
                              id="description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              onChange={this.inputChange}
                              value={this.state.description}
                              placeholder="Description (optional)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                      onClick={() => this.createGroup()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  editGroupModal = () => {
    return (
      <Transition.Root show={this.state.editModal !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeEditModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Group
                      </Dialog.Title>
                      <div>
                        <div className="mt-3">
                          <label htmlFor="name" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Name of Group
                          </label>
                          <div className="mt-1">
                            <input
                              name="name"
                              id="name"
                              onChange={this.inputChange}
                              value={this.state.name}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="name" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Group Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={3}
                              name="description"
                              id="description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              onChange={this.inputChange}
                              value={this.state.description}
                              placeholder="Description (optional)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                      onClick={() => this.updateGroup()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => this.closeEditModal()}
                    >
                      Cancel
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  deleteGroupModal = () => {
    return (
      <Transition.Root show={this.state.deleteGroupId !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeDeleteGroupModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={()=>this.closeDeleteGroupModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete Group
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete a group? Group will be permanently removed forever. This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 flex">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={()=>this.closeDeleteGroupModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 ml-3"
                      onClick={()=>this.deleteGroup()}
                    >
                      Remove
                    </button>
                  </div>
                  <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  render () {
    return (
      <SettingsHolder>
        <SEOHeaders title={'Groups'} appendTitle/>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              Add members in your space to groups
            </p>
            <div className="mt-4 flex rounded-md">
              <div className="relative flex items-stretch">
                <input
                  id="search"
                  name="search"
                  placeholder="Search groups..."
                  onChange={this.searchInputChange}
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </button>
              <button
                type="button"
                onClick={()=>this.openModal()}
                className="block ml-4 rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
              >
                New Group
              </button>
            </div>
          </div>
        </div>
  
        <div className="mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Group
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Members
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {this.state.groups.map((group) => (
                <tr key={group.id}>
                  <td className="py-3 text-sm text-gray-500 hover:text-gray-700">
                    <div className='cursor-pointer flex' onClick={()=>this.openEditModal(group)}>
                      {group.name} <PencilSquareIcon className="w-4 h-4 mt-0.5 mr-1 ml-1.5"/>
                    </div>
                  </td>
                  <td className="py-3 text-sm text-gray-500 hover:text-gray-700">
                    <Link to={`/d/${this.props.params.namespace}/g/${group.id}/users`} className='flex'>
                      <UsersIcon className='w-4 h-4 mt-0.5 mr-1'/>
                      {group.count} Members
                    </Link>
                  </td>
                  <td className="w-6 py-3 text-sm text-gray-500 hover:text-gray-700">
                    <TrashIcon className='w-4 h-4 cursor-pointer' onClick={()=>this.openDeleteGroupModal(group.id)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {this.state.groupsCount > groupsPerPage ?
          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
              <Link
                to={'#'}
                onClick={()=>this.setState({page: this.state.page > 0 ? this.state.page-1 : 0},()=>this.loadGroups())}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </Link>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {Array.from(Array(Math.ceil(this.state.groupsCount/groupsPerPage)).keys()).map((el: number) => {
                return <Link
                  key={el}
                  to={"#"}
                  onClick={()=>this.setState({page: el},()=>this.loadGroups())}
                  className={this.state.page !== el ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" : "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"}
                >
                  {el+1}
                </Link>
              })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
              <Link
                to={"#"}
                onClick={()=>this.setState({page: this.state.page < Math.ceil(this.state.groupsCount / groupsPerPage) ? this.state.page+1 : this.state.page},()=>this.loadGroups())}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Link>
            </div>
          </nav>
        : null}

        {this.newGroupModal()}
        {this.editGroupModal()}
        {this.deleteGroupModal()}

      </SettingsHolder>
    );
  }
}

export default function GroupsWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Groups params={params} navigate={navigate}/>
}