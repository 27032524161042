import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { storeCredentials } from './utils'
import { SEOHeaders } from './SeoHeaders'
import { Error } from './Error'

interface SigninProps {
  navigate: any
  params: any
}

type SigninStates = {
  logError: string
  logErrors: any
  logEmail: string
  logPassword: string
}

class Signin extends Component <SigninProps, SigninStates> {
  constructor (props: SigninProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as SigninStates)
  }

  signin = () => {
    this.setState({
      logError: '',
      logErrors: {}
    })
    fetch(
      config.app.apiUri + '/api/v1/me/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          password: this.state.logPassword
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/d/')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <>
        <SEOHeaders title={'Sign In'} appendTitle/>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/vortn-logo.svg"
              alt="Vortn"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6">

              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="logEmail"
                    type="email"
                    autoComplete="email"
                    value={this.state.logEmail}
                    onChange={this.inputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="logPassword"
                    type="password"
                    autoComplete="current-password"
                    value={this.state.logPassword}
                    onChange={this.inputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                  onClick={()=>this.signin()}
                >
                  Sign in
                </button>
              </div>

              <Error logError={this.state.logError} setLogError={(e:string)=>this.setState({logError:e})} />

            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{' '}
              <Link to={'/signup'} className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Create an account!
              </Link>
            </p>
            
            <div className="pt-8 text-sm w-full flex justify-center">
              <Link to={'/forgot'} className="font-semibold text-blue-600 hover:text-blue-500">
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function SigninWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Signin params={params} navigate={navigate}/>
 }