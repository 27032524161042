import React, { Component } from 'react'
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom'
import { config } from './config'
import { SEOHeaders } from "./SeoHeaders"
import { Error } from './Error'
import { Success } from './Success'

interface ResetProps {
  navigate: any
  params: any
  searchParams: any
}

type ResetStates = {
  logError: string
  logErrors: any
  logMessage: string
  logPassword: string
}

class Reset extends Component <ResetProps, ResetStates> {
  constructor (props: ResetProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logMessage: '',
      logPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as ResetStates)
  }

  reset = () => {
    fetch(
      config.app.apiUri + '/api/v1/me/reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          password: this.state.logPassword,
          uid: this.props.searchParams[0].get('key')
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            logMessage: json.message
          })
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <div className="flex min-h-full min-w-[450px] items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
         <SEOHeaders title={'Reset Password'} appendTitle/>
         <div className="w-full max-w-sm space-y-10">
            <div>
              <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=600" alt="Your Company"/>
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset Password</h2>
            </div>
            <div className="space-y-6">
              <div className="relative -space-y-px rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"></div>
                  <div>
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input value={this.state.logPassword} onChange={this.inputChange} id="password" name="logPassword" type="password" required className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" placeholder="Password"/>
                  </div>
              </div>
              <div>
                <button onClick={()=>this.reset()} className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Reset password</button>
                <Success logMessage={this.state.logMessage} setLogMessage={(e:string)=>this.setState({logMessage:e})} />
                <Error logError={this.state.logError} setLogError={(e:string)=>this.setState({logError:e})} />
              </div>
            </div>
            <p className="text-center text-sm leading-6 text-gray-500">
              Sign in?{' '}
              <Link to="/singin" className="font-semibold text-blue-600 hover:text-blue-500">Sing in!</Link>
            </p>
         </div>
      </div>
    );
  }
}

export default function ResetWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   const searchParams = useSearchParams()
   return <Reset params={params} navigate={navigate} searchParams={searchParams}/>
 }