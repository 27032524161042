import React, { Component, Fragment } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import DashboardHolder from './DashboardHolder'
import { useEditor } from "@wysimark/react"
import { SEOHeaders } from "./SeoHeaders"
import {
  PlusIcon,
  XMarkIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import {
  MagnifyingGlassIcon,
  CheckIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import Moment from 'react-moment'
import { Error } from './Error'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface AgentsProps {
  navigate: any
  params: any
  editor: any
}

type AgentsStates = {
  agents: any[]
  access: any[]
  addAccessRunnerSearch: string
  addAccessSelectedResult: any
  addAccessRunnerResults: any[]
  deleteModalId: string
  deleteError: string
}

class Agents extends Component <AgentsProps, AgentsStates> {
  constructor (props: AgentsProps) {
    super(props)
    this.state = {
      agents: [],
      access: [],
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: [],
      deleteModalId: '',
      deleteError: ''
    }
  }

  componentDidMount(){
    this.loadAllGroups()
  }

  loadAgents = () => {
    const authToken = getCredentials()
    const accessArray = this.state.access.map((acc)=>acc.id)
    fetch(
      config.app.apiUri+'/api/v1/namespace/'+this.props.params.namespace+'/agents?access='+accessArray.join(','), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({ agents: json.agents })
        } else {
          this.setState({ agents: [] })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ addAccessRunnerSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ addAccessRunnerSearch: '', addAccessRunnerResults: [] } as any)
    }
  }

  addAccess = (group: any) => {
    if (group === null) return
    const access = this.state.access
    access.push(group)
    this.setState({
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: [],
      access
    }, ()=>this.loadAgents())
  }

  removeAccess = (id: string) => {
    const access = this.state.access
    const newaccess = access.filter(el => el.id !== id)
    this.setState({
      access: newaccess
    }, ()=>this.loadAgents())
  }

  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.addAccessRunnerSearch) {
      search = '&search=' + this.state.addAccessRunnerSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            addAccessRunnerResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadAllGroups = () => {
    const authToken = getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({access: json.groups}, ()=>this.loadAgents())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteAgent = () => {
    const authToken = getCredentials()
    const agentId = this.state.deleteModalId
    fetch(
      config.app.apiUri+'/api/v1/namespace/'+this.props.params.namespace+'/agents/'+agentId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.closeDeleteModal()
          this.loadAgents()
        }
        if (json.status === 'error') {
          this.setState({deleteError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  closeDeleteModal = () => {
    this.setState({
      deleteError: '',
      deleteModalId: ''
    })
  }
  
  openDeleteModal = (agentId: string) => {
    this.setState({
      deleteError: '',
      deleteModalId: agentId
    })
  }
  
  deleteAgentModal = () => {
    return (
      <Transition.Root show={this.state.deleteModalId !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeDeleteModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={()=>this.closeDeleteModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete Agent
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete an agent? Agent will be permanently removed forever. This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 flex">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={()=>this.closeDeleteModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 ml-3"
                      onClick={()=>this.deleteAgent()}
                    >
                      Remove
                    </button>
                  </div>
                  <Error logError={this.state.deleteError} setLogError={(e:string)=>this.setState({deleteError:e})} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  
  render () {
    return (
      <DashboardHolder>
        <div className='h-screen flex flex-col'>
          <SEOHeaders title={'Agents'} appendTitle/>
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/d/'+this.props.params.namespace+'/a'
                        } className="font-medium text-gray-700 hover:text-gray-700">
                          Agents
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className='flex flex-row flex-wrap mt-3'>
              <div>
                <MagnifyingGlassIcon className="mr-1.5 mt-2.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </div>
              {this.state.access.map((access: any) => {
                return <div key={access.id} className='pr-1 mt-1'>
                  <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    {access.name}
                    <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                      <span className="sr-only">Remove</span>
                      <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                        <path d="M4 4l6 6m0-6l-6 6" />
                      </svg>
                      <span className="absolute -inset-1" />
                    </button>
                  </span>
                </div>
              })}
              <div>
                <Combobox as="div" value={this.state.addAccessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                  <div className="relative">
                    <Combobox.Input
                      className="rounded-md mt-1 border-0 bg-white py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                      onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                      displayValue={(group: any) => ''}
                      placeholder='Display from...'
                    />
                    {this.state.addAccessRunnerResults.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                        {this.state.addAccessRunnerResults.map((group: any) => (
                          <Combobox.Option
                            key={group.id}
                            value={group}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                active ? 'bg-blue-600 text-white' : 'text-gray-900'
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                      active ? 'text-white' : 'text-blue-600'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
            </div>

            <div className='mt-5'>
              <button
                type="button"
                onClick={()=>this.props.navigate('/d/'+this.props.params.namespace+'/a/new')}
                className="inline-flex rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
              >
                <PlusIcon className="-ml-0.5 mr-1.5 mt-0.5 h-4 w-4 text-blue-900" aria-hidden="true" />
                New Agent
              </button>
            </div>
          </div>
          
          <div className="mt-4 overflow-y-auto flex flex-col flex-1 px-4 sm:px-6 lg:px-8 pt-2 pb-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Agent
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    Scheduled
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {this.state.agents.map((agent) => (
                  <tr key={agent.id}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500 hover:text-gray-700 sm:pl-0">
                      <Link to={'/d/'+this.props.params.namespace+'/a/'+agent.id}>{agent.name}</Link>
                    </td>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                      {agent.cron ? 'Yes': 'No'}
                    </td>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                      <Moment format="YYYY/MM/DD HH:mm:ss">
                        {agent.createdAt}
                      </Moment>
                    </td>
                    <td className="whitespace-nowrap w-6 py-3 text-sm text-gray-500 cursor-pointer" onClick={()=>this.openDeleteModal(agent.id)}>
                      <TrashIcon className='w-4 h-4' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {this.deleteAgentModal()}
          
        </div>

      </DashboardHolder>
    );
  }
}

export default function AgentsWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const editor = useEditor({})
  return <Agents params={params} navigate={navigate} editor={editor}/>
}