import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Switch } from '@headlessui/react'
import DashboardHolder from './DashboardHolder'
import { Editable, useEditor } from "@wysimark/react"
import { RocketLaunchIcon } from '@heroicons/react/24/outline'
import { SEOHeaders } from "./SeoHeaders"

interface VerifyEmailProps {
  navigate: any
  params: any
}

type VerifyEmailStates = {
  success: any
}

class VerifyEmail extends Component <VerifyEmailProps, VerifyEmailStates> {
  constructor (props: VerifyEmailProps) {
    super(props)
    this.state = {
      success: undefined
    }
  }
  
  componentDidMount(){
    this.validateEmail()
  }

  validateEmail = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/mailvalidate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          token: this.props.params.token
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({success: true})
        } else {
          this.setState({success: false})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <DashboardHolder>
        <SEOHeaders title={'Validate email'} appendTitle/>
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div className="flex">
                    <Link to={'/'} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      Verifying email
                    </Link>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {this.state.success === true ?
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Thank you!
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Your e-mail has been validated. Welcome to Vortn.com
                </p>
                <button
                  type="button"
                  onClick={()=>this.props.navigate('/d/')}
                  className="mt-10 inline-flex items-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                >
                  Forward
                  <RocketLaunchIcon className="ml-1.5 h-4 w-4 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        :null}
        {this.state.success === false ?
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Something went wrong!
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Contact us at <a href="mailto:contact@vortn.com">contact@vortn.com</a>
                </p>
              </div>
            </div>
          </div>
        :null}
        {this.state.success === undefined ?
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Loading...
                </p>
              </div>
            </div>
          </div>
        :null}
      </DashboardHolder>
    );
  }
}

export default function VerifyEmailWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <VerifyEmail params={params} navigate={navigate}/>
}