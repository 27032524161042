import { useCallback, useMemo, useState } from "react";
import { useSTTWorker } from "./useSTTWorker";

export interface Transcriber {
    ready: boolean
    processing: boolean
    output: any
}

export function useTranscriber(): Transcriber {
    const [ready, setReady] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [output, setOutput] = useState(null);

    const webWorker = useSTTWorker((event) => {
        switch (event.data.status) {
            case 'loaded':
            setReady(true);
            break;
            case 'complete':
            setProcessing(false);
            setOutput(event.data.output);
            break;
        }
    });

    const startTranscribe = useCallback(
        async (audio: any) => {
            setProcessing(true);
            webWorker.postMessage({audio});
        },
        [webWorker],
    )

    const loadTranscriber = useCallback(
        async () => {
            webWorker.postMessage({load: true});
        },
        [webWorker],
    )

    const voicer = useMemo(() => {
        return {
            ready,
            processing,
            start: startTranscribe,
            load: loadTranscriber,
            output
        };
    }, [
        ready,
        processing,
        startTranscribe,
        loadTranscriber,
        output
    ]);

    return voicer;
}
