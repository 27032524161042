import React, { Component } from 'react'
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom'
import { config } from './config'
import ProfileHolder from './ProfileHolder'
import { getCredentials } from './utils'
import { SEOHeaders } from "./SeoHeaders"
import { ExclamationTriangleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

interface ProfileProps {
  navigate: any
  params: any
  searchParams: any
}

type ProfileStates = {
  fullname: string
  about: string
  email: string
  mailvalidated: boolean
  error: string
  success: string
  mailsent: boolean
}

class Profile extends Component <ProfileProps, ProfileStates> {
  constructor (props: ProfileProps) {
    super(props)
    this.state = {
      fullname: '',
      about: '',
      email: '',
      mailvalidated: true,
      error: '',
      success: '',
      mailsent: false
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as ProfileStates)
  }

  componentDidMount(){
    this.load()
  }

  load = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            fullname: json.data.fullname,
            about: json.data.about ? json.data.about : '',
            email: json.data.email,
            mailvalidated: json.data.mailvalidated
          })
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  update = () => {
    this.setState({
      success: '',
      error: ''
    })
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          fullname: this.state.fullname,
          about: this.state.about
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            success: json.message
          })
        } else {
          this.setState({
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  requestValidation = async () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/requestmailvalidate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            mailsent: true
          })
        } else {
          this.setState({
            mailsent: false
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <ProfileHolder>
        <SEOHeaders title={'Profile Settings'} appendTitle/>
        <div className="sm:col-span-4">
          <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900">
            Name
          </label>
          <div className="mt-2">
            <input
              id="fullname"
              name="fullname"
              value={this.state.fullname}
              onChange={this.inputChange}
              className="block rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2 flex">
            <input
              id="email"
              name="email"
              disabled={true}
              value={this.state.email}
              className="block rounded-l-md w-full/2 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 w-full lg:w-1/2"
            />
            {this.state.mailvalidated === false && this.state.mailsent === false ? <button className="bg-white text-gray-900 ring-inset ring-1 ring-gray-300 p-2 rounded-r-md flex" onClick={()=>this.requestValidation()}><ExclamationTriangleIcon className='mt-1 mr-1 w-4 h-4'/>Validate Email</button> : null}
            {this.state.mailvalidated === false && this.state.mailsent === true ? <button className="bg-white text-gray-900 ring-inset ring-1 ring-gray-300 p-2 rounded-r-md flex"><ExclamationTriangleIcon className='mt-1 mr-1 w-4 h-4'/>Email sent!</button> : null}
            {this.state.mailvalidated === true ? <button className="bg-white text-gray-900 p-2 border-0 ring-inset ring-1 ring-gray-300 rounded-r-md flex"><ShieldCheckIcon className='mt-1 mr-1 w-4 h-4'/>Validated</button> : null}
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
            About
          </label>
          <div className="mt-2">
            <textarea
              id="about"
              name="about"
              value={this.state.about}
              onChange={this.inputChange}
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-6">
          <button onClick={()=>this.update()} type="button" className="rounded-md bg-blue-100 px-10 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
            Save
          </button>
        </div>

      </ProfileHolder>
    );
  }
}

export default function ProfileWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   const searchParams = useSearchParams()
   return <Profile params={params} navigate={navigate} searchParams={searchParams}/>
 }