import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Dialog } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  ClockIcon,
  CheckIcon,
  LockOpenIcon,
  UsersIcon,
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon
} from '@heroicons/react/24/outline'
import { Success } from './Success'
import { Error } from './Error'

const navigation = [
  { name: 'Features', href: '#features' },
  { name: 'AI Agents', href: '#aiagents' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Contact', href: '#contact' },
  { name: 'Blogs', href: 'https://blog.vortn.com' }
]
const tiers = [
  {
    name: 'Starter',
    id: 'tier-starter',
    href: '/signup',
    jointext: 'Sign up',
    priceMonthly: '-$1',
    pricePer: '/ month + compute',
    description: 'Vortn is free for teams up to 5 members with included $1 of AI compute each month.',
    features: [
      'AI Chat',
      'Context pages ',
      'AI Agents',
      'Sandboxes & tools'
    ],
    mostPopular: false,
    contact: false
  },
  {
    name: 'Business',
    id: 'tier-business',
    href: '/signup',
    jointext: 'Start Now',
    priceMonthly: '$9',
    pricePer: ' / month / member + compute',
    description: 'A plan that scales with your rapidly growing business with predictable cost.',
    features: [
      'AI Chat',
      'Context pages ',
      'AI Agents',
      'Sandboxes & tools',
      '24-hour support',
    ],
    mostPopular: true,
    contact: false
  },
  {
    name: 'On-Premise',
    id: 'tier-onprem',
    href: 'mailto:contact@vortn.com',
    jointext: 'Contact Us',
    priceMonthly: '$???',
    pricePer: '/ deployment',
    description: 'Worried about privacy? Contact us for full on-premise solution including AI engine.',
    features: [
      'AI Chat',
      'Context pages ',
      'AI Agents',
      'Sandboxes & tools',
      '24-hour support',
      'Custom integrations'
    ],
    mostPopular: false,
    contact: true
  },
]
const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface LandingProps {
  navigate: any
  params: any
}

type LandingStates = {
  logged: boolean
  mobileMenuOpen: boolean
  isContacting: boolean
  contactFirstname: string
  contactLastname: string
  contactMessage: string
  contactEmail: string
  contactError: string
  contactSuccess: string
}

class Landing extends Component <LandingProps, LandingStates> {
  constructor (props: LandingProps) {
    super(props)
    this.state = {
      logged: false,
      mobileMenuOpen: false,
      isContacting: false,
      contactFirstname: '',
      contactLastname: '',
      contactMessage: '',
      contactEmail: '',
      contactError: '',
      contactSuccess: ''
    }
  }

  componentDidMount(){
    this.loadMe()
  }
  
  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as LandingStates)
  }

  setMobileMenuOpen = () => {
    this.setState({mobileMenuOpen: true})
  }

  setMobileMenuClosed = () => {
    this.setState({mobileMenuOpen: false})
  }

  loadMe = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({ logged: true })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  handleForm = () => {
    this.setState({
      isContacting: true,
      contactError: '',
      contactSuccess: ''
    })
    fetch(
      config.app.apiUri + '/api/v1/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstname: this.state.contactFirstname,
          lastname: this.state.contactLastname,
          email: this.state.contactEmail,
          message: this.state.contactMessage
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            isContacting: false,
            contactFirstname: '',
            contactLastname: '',
            contactMessage: '',
            contactEmail: '',
            contactError: '',
            contactSuccess: json.message
          })
        } else {
          this.setState({
            contactError: json.message,
            isContacting: false
          })
        }
      })
  }
  
  render () {
    return (
      <div className="bg-white" id="product">
      {/* Header */}
        <header className="absolute inset-x-0 top-0 z-50">
          <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex">
              <Link to={'/'} className="-m-1.5 p-1.5">
                <span className="sr-only">Vortn.com</span>
                <img
                  className="h-8 w-auto mr-12"
                  src="/vortn-logo.svg"
                  alt=""
                />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => this.setMobileMenuOpen()}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              {this.state.logged === false ?
                <a href="/signin" className="text-sm font-semibold leading-6 text-gray-900">
                  Log in <span aria-hidden="true">&rarr;</span>
                </a>
              : 
                <a href="/d/" className="text-sm font-semibold leading-6 text-gray-900">
                  Enter <span aria-hidden="true">&rarr;</span>
                </a>
              }
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={this.state.mobileMenuOpen} onClose={()=>this.setMobileMenuClosed()}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link to={'/'} className="-m-1.5 p-1.5">
                  <span className="sr-only">Vortn.com</span>
                  <img
                    className="h-8 w-auto"
                    src="/vortn-logo.svg"
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={()=>this.setMobileMenuClosed()}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={()=>this.setMobileMenuClosed()}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <Link
                      to={'/signin'}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Signin
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>

        <main className="isolate">

          <div className="relative isolate pt-14">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffffff] to-[#2563EB] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              />
            </div>
            <div className="py-24">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Think Bigger with Your AI Second Brain
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    From managing knowledge, offloading decision making to automating tasks, our AI second brain evolves with your business, offering solutions that scale with your needs.
                  </p>
                  <div className="mt-5 flex items-center justify-center gap-x-6">
                    <Link
                      to={"/signup"}
                      className="rounded-md bg-blue-100 px-3 py-2 font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                    >
                      Get started
                    </Link>
                    <a href="#features" className="text-sm font-semibold leading-6 text-gray-900">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
                <div className="mt-12 flow-root sm:mt-20">
                  <img
                    alt="App screenshot"
                    src="/image1.jpg"
                    width={1362}
                    height={786}
                    className="rounded-md shadow-lg ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            >
              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffffff] to-[#2563EB] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              />
            </div>
          </div>
          

          {/*
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                alt="Reform"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                alt="Tuple"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                alt="SavvyCal"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                alt="Statamic"
                width={158}
                height={48}
              />
            </div>
          </div>
          */}



          <div className="bg-white py-24" id="features">
            <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
              <p className="mt-2 max-w-lg text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
                Features
              </p>
              <div className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-6 lg:grid-rows-2">
                <div className="relative lg:col-span-3">
                  <div className="absolute inset-px rounded-lg bg-white" />
                  <div className="relative flex h-full flex-col overflow-hidden">
                    <div
                      // alt=""
                      // src="/image-rbac.jpg"
                      className="landing-image-gradient-1"
                    />
                    <div className="p-10">
                      <h3 className="text-sm/4 font-semibold text-blue-600">Second Brain</h3>
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Pages with RBAC controls</p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                        Build second brain with pouring your knowledge to pages. Each page have configurable Role-Based-Access-Control which allows stakeholders to align accesses with any organizational structure.
                      </p>
                    </div>
                  </div>
                  <div className="pointer-events-none absolute inset-px rounded-lg shadow-md ring-1 ring-black/5" />
                </div>
                <div className="relative lg:col-span-3">
                  <div className="absolute inset-px rounded-lg bg-white" />
                  <div className="relative flex h-full flex-col overflow-hidden rounded-lg">
                    <div
                      // alt=""
                      // src="/image-context.jpg"
                      className="landing-image-gradient-2"
                    />
                    <div className="p-10">
                      <h3 className="text-sm/4 font-semibold text-blue-600">AI Context</h3>
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Execute AI with context</p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                        Anytime you execute AI in Vortn you have option to on-fly include any pages. Knowledge from included pages can be used as context in AI execution.
                      </p>
                    </div>
                  </div>
                  <div className="pointer-events-none absolute inset-px rounded-lg shadow-md ring-1 ring-black/5" />
                </div>
                <div className="relative lg:col-span-2">
                  <div className="absolute inset-px rounded-lg bg-white " />
                  <div className="relative flex h-full flex-col overflow-hidden rounded-lg">
                    <div
                      // alt=""
                      // src="https://tailwindui.com/plus/img/component-images/bento-01-speed.png"
                      className="landing-image-gradient-3"
                    />
                    <div className="p-10">
                      <h3 className="text-sm/4 font-semibold text-blue-600">Integrate</h3>
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">External access</p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                        Use Vortn as AI API for any external tools. API calls can be enriched with injected context to provide up to date knowledge from pages.
                      </p>
                    </div>
                  </div>
                  <div className="pointer-events-none absolute inset-px rounded-lg shadow-md ring-1 ring-black/5" />
                </div>
                <div className="relative lg:col-span-2">
                  <div className="absolute inset-px rounded-lg bg-white" />
                  <div className="relative flex h-full flex-col overflow-hidden rounded-lg">
                    <img
                      alt=""
                      src="/image-integrations.jpg"
                      className="h-80 object-cover object-center"
                    />
                    <div className="p-10">
                      <h3 className="text-sm/4 font-semibold text-blue-600">Power of tools</h3>
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Tools and Sandboxes</p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                        Vortn provides AI with wide set of tools: python and javascript sandboxes, retrieve website content or execute API calls to any automation framework.
                      </p>
                    </div>
                  </div>
                  <div className="pointer-events-none absolute inset-px rounded-lg shadow-md ring-1 ring-black/5" />
                </div>
                <div className="relative lg:col-span-2">
                  <div className="absolute inset-px rounded-lg bg-white" />
                  <div className="relative flex h-full flex-col overflow-hidden rounded-lg">
                    <div
                      // alt=""
                      // src="/security.jpg"
                      className="landing-image-gradient-4"
                    />
                    <div className="p-10">
                      <h3 className="text-sm/4 font-semibold text-blue-600">Privacy</h3>
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">On-Premise Solutions</p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                        Vortn can be deployed on-premise with all its tools including AI Engine providing 100% execution isolation and data privacy.
                      </p>
                    </div>
                  </div>
                  <div className="pointer-events-none absolute inset-px rounded-lg shadow-md ring-1 ring-black/5" />
                </div>
              </div>
            </div>
          </div>




          
          {/*
          <div className="relative isolate overflow-hidden bg-white">
            <svg
              aria-hidden="true"
              className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
              <defs>
                <pattern
                  x="50%"
                  y={-1}
                  id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <rect fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" width="100%" height="100%" strokeWidth={0} />
            </svg>
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <a href="#" className="inline-flex space-x-6">
                    <span className="rounded-full bg-blue-600/10 px-3 py-1 text-sm font-semibold leading-6 text-blue-600 ring-1 ring-inset ring-blue-600/10">
                      What's new
                    </span>
                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>Just shipped v1.0</span>
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    </span>
                  </a>
                </div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Chat photo and AI for businesses
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Use AI on flow with flexible inclusion of knowledge base in AI
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Get started
                  </a>
                  <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mx-auto mt-16 flex max-w-2xl sm:mt-20 lg:ml-7 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                  <img
                    alt="App screenshot"
                    src="/image2.jpg"
                    width={1220}
                    height={814}
                    className="w-[58rem] rounded-md shadow-xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          */}




          {/*
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">No server? No problem.</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste
                  dolor cupiditate blanditiis.
                </p>
              </div>
            </div>
            <div className="relative overflow-hidden pt-16">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <img
                  alt="App screenshot"
                  src="/image1.jpg"
                  width={1195}
                  height={814}
                  className="mb-[-30%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                />
                <div aria-hidden="true" className="relative">
                  <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
                </div>
              </div>
            </div>
            <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-10 md:mt-14 lg:px-8">
              <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-blue-600" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          */}

          {/* Testimonial section */}
          <div className="relative isolate overflow-hidden bg-white px-6 py-24 lg:overflow-visible lg:px-0" id="aiagents">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="lg:max-w-lg">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI Agents</h1>
                    <p className="mt-6 text-xl leading-8 text-gray-700">
                      Meet your AI Agents: intelligent, promptable assistants designed to automate tasks, streamline workflows, and enhance decision-making. Fully adaptable to your business needs to help you stay productive by handling routine operations so you can focus on what matters most.
                    </p>
                  </div>
                </div>
              </div>
              <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img
                  alt=""
                  src="/image-ai-agent.jpg"
                  className="w-[48rem] max-w-none rounded-md shadow-xl ring-1 ring-gray-900/10 sm:w-[57rem]"
                />
              </div>
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                    <p>
                      The heart of each AI Agent is its prompt. With it it's behavior is defined by precise instructions, allowing it to perform tasks, make decisions, and automate workflows according to your needs. Each AI agent can be extended with following features:
                    </p>
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">        
                        <UsersIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                        <span>
                          <strong className="font-semibold text-gray-900">Ownership & Access</strong> options provide control over who is owner of AI agent and who can edit it's properties.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <ClockIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                        <span>
                          <strong className="font-semibold text-gray-900">Scheduling</strong> allows to define at which hour, day, week AI agent executes its directives.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <LockOpenIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                        <span>
                          <strong className="font-semibold text-gray-900">Page Access</strong> defines which pages with context are included with execution. With this AI agents have up to date knowledge.
                        </span>
                      </li>
                    </ul>
                    <p className="mt-8">
                      All Vortns AI systems include tools that can be called at any point. Vortn can open website and get content, send an email to space member, create notification, execute API request and run javascript and python code in sandbox.
                    </p>
                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Get Free Consultation</h2>
                    <p className="mt-6">
                      Whether you're exploring the possibilities or ready to dive deep, we'll provide personalized strategies to ensure your business gets the most out of our AI-powered platform. Book a consultation today and take the next step toward smarter, more automated operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pricing section */}
          <div className="py-24" id="pricing">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-4xl text-center">
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Pricing plans
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Each space has it's own balance that can be recharged with any accepted payment method. You can start with out starter plan without a credit card.
              </p>
              <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {tiers.map((tier, tierIdx) => (
                  <div
                    key={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                      tierIdx === 0 ? 'lg:rounded-r-none' : '',
                      tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                      'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                    )}
                  >
                    <div>
                      <div className="flex items-center justify-between gap-x-4">
                        <h3
                          id={tier.id}
                          className={classNames(
                            tier.mostPopular ? 'text-blue-600' : 'text-gray-900',
                            'text-lg font-semibold leading-8'
                          )}
                        >
                          {tier.name}
                        </h3>
                        {tier.mostPopular ? (
                          <p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                            Most popular
                          </p>
                        ) : null}
                      </div>
                      <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                      <p className="mt-6 flex items-baseline gap-x-1">
                        <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                        <span className="text-sm font-semibold leading-6 text-gray-600">{tier.pricePer}</span>
                      </p>
                      <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-blue-600" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <a
                      href={tier.href}
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.mostPopular
                          ? 'rounded-md bg-blue-100 px-3 py-1.5 font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300'
                          : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                        'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                      )}
                    >
                      {tier.jointext}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* FAQs */}
          {/*
          <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                  <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          */}

          {/* CTA section */}
          <div className="bg-blue-100 border border-blue-200 mb-24">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Ready to dive in?<br/>
                Test it out for free!
              </h2>
              <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                <Link
                  to={'/signup'}
                  className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Get started
                </Link>
                <a href="#contact" className="text-sm font-semibold leading-6 text-gray-900">
                  Contact us <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          
          <div className="relative isolate bg-white" id="contact">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
              <div className="relative px-6 pb-10 pt-20 lg:static lg:px-8">
                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                  <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                    <svg
                      aria-hidden="true"
                      className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    >
                      <defs>
                        <pattern
                          x="100%"
                          y={-1}
                          id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                          width={200}
                          height={200}
                          patternUnits="userSpaceOnUse"
                        >
                          <path d="M130 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                      </defs>
                      <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                      <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                        <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                      </svg>
                      <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
                    </svg>
                  </div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Have questions or need assistance? We're here to help! Reach out to us anytime, and our team will get back to you as soon as possible.
                  </p>
                  <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                    {/*
                    <div className="flex gap-x-4">
                      <dt className="flex-none">
                        <span className="sr-only">Telephone</span>
                        <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                      </dt>
                      <dd>
                        <a href="tel:+1 (555) 234-5678" className="hover:text-gray-900">
                          +386 (40) 575-338
                        </a>
                      </dd>
                    </div>
                    */}
                    <div className="flex gap-x-4">
                      <dt className="flex-none">
                        <span className="sr-only">Email</span>
                        <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                      </dt>
                      <dd>
                        <a href="mailto:contact@vortn.com" className="hover:text-gray-900">
                          contact@vortn.com
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <form action="#" method="POST" className="px-6 pb-10 pt-20 lg:px-8">
                <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                      <label htmlFor="contactFirstname" className="block text-sm font-semibold leading-6 text-gray-900">
                        First name
                      </label>
                      <div className="mt-2.5">
                        <input
                          id="contactFirstname"
                          name="contactFirstname"
                          value={this.state.contactFirstname}
                          onChange={this.inputChange}
                          type="text"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="contactLastname" className="block text-sm font-semibold leading-6 text-gray-900">
                        Last name
                      </label>
                      <div className="mt-2.5">
                        <input
                          id="contactLastname"
                          name="contactLastname"
                          value={this.state.contactLastname}
                          onChange={this.inputChange}
                          type="text"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="contactEmail" className="block text-sm font-semibold leading-6 text-gray-900">
                        Email
                      </label>
                      <div className="mt-2.5">
                        <input
                          id="contactEmail"
                          name="contactEmail"
                          value={this.state.contactEmail}
                          onChange={this.inputChange}
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="contactMessage" className="block text-sm font-semibold leading-6 text-gray-900">
                        Message
                      </label>
                      <div className="mt-2.5">
                        <textarea
                          id="contactMessage"
                          name="contactMessage"
                          value={this.state.contactMessage}
                          onChange={this.inputChange}
                          rows={4}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 flex justify-end">
                    <button
                      disabled={this.state.isContacting}
                      onClick={()=>this.handleForm()}
                      type="button"
                      className="rounded-md bg-blue-100 px-3 py-2 font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                    >
                      {this.state.isContacting ? 'Sending...' : 'Send message'}
                    </button>
                  </div>
                  <Error logError={this.state.contactError} setLogError={(e:string)=>this.setState({contactError:e})} />
                  <Success logSuccess={this.state.contactSuccess} setLogSuccess={(e:string)=>this.setState({contactSuccess:e})} />
                </div>
              </form>
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer className="bg-white">
          <div className="mx-auto max-w-7xl overflow-hidden px-6 pt-20 sm:pt-24 pb-10">
            <nav aria-label="Footer" className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
              {navigation.map((item) => (
                <div key={item.name} className="pb-6">
                  <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                    {item.name}
                  </a>
                </div>
              ))}
            </nav>
            {/*
            <div className="mt-10 flex justify-center space-x-10">
              {social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
            */}
            <p className="mt-10 text-center text-xs leading-5 text-gray-500">
              &copy; 2024 Arcloop LIMITED. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default function LandingWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Landing params={params} navigate={navigate}/>
}

const social = [
  {
    name: 'Facebook',
    href: '#',
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: '#',
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'X',
    href: '#',
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
      </svg>
    ),
  },
  {
    name: 'GitHub',
    href: '#',
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'YouTube',
    href: '#',
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]