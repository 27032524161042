import React, { Component, Fragment } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Switch, Dialog, Transition, RadioGroup } from '@headlessui/react'
import SettingsHolder from './SettingsHolder'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Moment from 'react-moment'
import { TrashIcon } from '@heroicons/react/24/outline'
import { getDisplayBalance } from './utils'
import { SEOHeaders } from "./SeoHeaders"
import { Error } from './Error'
import { ErrorNotification } from './ErrorNotification'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface BillingProps {
  navigate: any
  params: any
}

type BillingStates = {
  stripe: any
  stripeSecret: any
  addingSuccess: boolean
  billings: any[]
  cards: any[]
  addCardError: string
  namespace: any
  openReload: boolean
  newModalOpen: boolean
  selectedCard: any
  reloadmin: number
  reloadamount: number
  reloadError: string
}

class Billing extends Component <BillingProps, BillingStates> {
  constructor (props: BillingProps) {
    super(props)
    this.state = {
      stripe: undefined,
      stripeSecret: undefined,
      addingSuccess: false,
      billings: [],
      cards: [],
      addCardError: '',
      namespace: undefined,
      openReload: false,
      newModalOpen: false,
      selectedCard: undefined,
      reloadmin: 0,
      reloadamount: 0,
      reloadError: ''
    }
  }

  componentDidMount () {
    this.loadBillings()
    this.loadCards()
    this.loadNamespace()
  }
  
  loadNamespace = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            namespace: json.namespace,
            reloadmin: json.namespace.reloadmin / 100000,
            reloadamount: json.namespace.reloadamount / 100000
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadBillings = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/billings', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            billings: json.billings
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }
  
  activeCard = (cardId: string) => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/cards/'+cardId+'/active', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          // done
        }
        if (json.status === 'error') {
          this.setState({addCardError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  resumeCard = (event: any, cardId: string) => {
    if (event !== undefined) {
      event.preventDefault()
    }
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/cards/'+cardId+'/resume', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadCards()
        }
        if (json.status === 'error') {
          this.setState({addCardError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  loadCards = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/cards', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          // get active
          const active = json.cards.find((c: any) => c.status !== null)
          // set cards with active
          this.setState({
            cards: json.cards,
            selectedCard: active && active.status ? active : null
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadPaymentAdd = (e: any = undefined) => {
    if (e !== undefined) {
      e.preventDefault()
    }
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/cards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const stripe = await loadStripe(json.stripeKey)
          this.setState({
            stripe,
            stripeSecret: json.clientSecret
          })
        }
        if (json.status === 'error') {
          this.setState({addCardError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteCard = (e: any = undefined, cardId: string) => {
    if (e !== undefined) {
      e.preventDefault()
    }
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/cards/'+cardId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadCards()
        }
        if (json.status === 'error') {
          this.setState({addCardError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  openReload = () => {
    this.setState({
      openReload: true
    })
  }

  closeReload = () => {
    this.setState({openReload: false})
  }
  
  setReload = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          ...this.state.namespace,
          reloadmin: this.state.reloadmin * 100000,
          reloadamount: this.state.reloadamount * 100000
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadNamespace()
          this.setState({
            openReload: false
          })
        }
        if (json.status === 'error') {
          this.setState({reloadError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  reloadSetupModal = () => {
    return (
      <Transition.Root show={this.state.openReload} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeReload()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-xs sm:p-6">
                  <div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Reload Setup
                      </Dialog.Title>
                      <div>
                        <div className="mt-3">
                          <label htmlFor="name" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Reload below Balance
                          </label>
                          <div className="mt-1">
                            <input
                              name="reloadmin"
                              id="reloadmin"
                              onChange={this.inputChange}
                              value={this.state.reloadmin}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="value" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Reload for Amount
                          </label>
                          <div className="mt-1">
                            <input
                              name="reloadamount"
                              id="reloadamount"
                              onChange={this.inputChange}
                              value={this.state.reloadamount}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 sm:col-start-2"
                          onClick={() => this.setReload()}
                        >
                          Setup
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => this.closeReload()}
                        >
                          Cancel
                        </button>
                      </div>
                      <Error logError={this.state.reloadError} setLogError={(e:string)=>this.setState({reloadError:e})} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  openAddCardModal = () => {
    this.setState({newModalOpen: true, addingSuccess: false, stripe: undefined, stripeSecret: undefined}, ()=>this.loadPaymentAdd())
  }

  closeModal = () => {
    this.setState({newModalOpen: false, addingSuccess: false, stripe: undefined, stripeSecret: undefined}, ()=>{
      setTimeout(()=>this.loadCards(), 1000)
    })
  }

  addCardModal = () => {
    return (
      <Transition.Root show={this.state.newModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="mt-2 text-center">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Payment Option
                    </Dialog.Title>
                    <div>
                      { this.state.addingSuccess === false && this.state.stripe && this.state.stripeSecret ?
                        <EmbeddedCheckoutProvider
                          stripe={this.state.stripe}
                          options={{
                            clientSecret: this.state.stripeSecret,
                            onComplete: ()=>this.setState({addingSuccess: true})
                          }}
                        >
                          <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                      : null}
                      { this.state.addingSuccess === true ?
                        <div>
                          <div className="mt-8">Success!</div>
                          <div className="mt-2">It may take few moments to reflect</div>
                          <div>adding payment option to our system</div>
                          <div className="mt-6 flex justify-center items-center">
                            <button
                              type="button"
                              onClick={()=>this.closeModal()}
                              className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      : null}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  
  render () {
    return (
      <SettingsHolder>
        <SEOHeaders title={'Billings'} appendTitle/>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="font-medium leading-6 text-gray-900">Balance {this.state.namespace ? (<>${getDisplayBalance(this.state.namespace.balance)}</>) : null}</h1>
            <p className="mt-2 text-sm text-gray-700">
              Setup automated reloading below to refill your balance. Spaces with 1 member will be refilled every month for $1.
            </p>
            <div className="mt-4">
              {this.state.namespace && this.state.namespace.reloadmin === null && this.state.namespace.reloadamount === null ?
                <button
                  type="button"
                  onClick={()=>this.openReload()}
                  className="block rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                >
                  Setup Reloading
                </button>
              : null}
              {this.state.namespace && this.state.namespace.reloadmin && this.state.namespace.reloadamount ?
                <button
                  type="button"
                  onClick={()=>this.openReload()}
                  className="block rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                >
                  Reloading active below ${this.state.reloadmin} for ${this.state.reloadamount}
                </button>
              : null}
            </div>
          </div>
        </div>

        <div>
          <div className="sm:flex sm:items-center mt-8">
            <div className="sm:flex-auto">
              <h1 className="text-base font-medium leading-6 text-gray-900">Payment Options</h1>
              <p className="mt-2 text-sm text-gray-700">
                Add your payment option that will be used for balance reloading.
              </p>
            </div>
          </div>
          {this.state.cards.length > 0 ?
            <div>
              <fieldset aria-label="Cards">
                <RadioGroup value={this.state.selectedCard} onChange={(selectedCard)=>this.setState({selectedCard}, ()=>this.activeCard(selectedCard.id))} className="relative -space-y-px rounded-md bg-white mt-4">
                  {this.state.cards.map((card: any, cardIdx: any) => (
                    <RadioGroup.Option
                      key={card.name}
                      value={card}
                      className={({ checked }: any) =>
                        classNames(
                          cardIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          cardIdx === this.state.cards.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'z-10 border-blue-200 bg-blue-50' : 'border-gray-200',
                          'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-5 md:pl-4 md:pr-6'
                        )
                      }
                    >
                      {({ focus, checked }: any) => (
                        <>
                          <span className="flex items-center text-sm">
                            <span
                              className={classNames(
                                checked ? 'border-transparent bg-blue-600' : 'border-gray-300 bg-white',
                                focus ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                                'flex h-4 w-4 items-center justify-center rounded-full border shrink-0'
                              )}
                              aria-hidden="true"
                            >
                              <span className="h-1.5 w-1.5 rounded-full bg-white" />
                            </span>
                            <span className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'ml-3 font-medium capitalize')}>
                              {card.method}
                            </span>
                          </span>
                          <span
                            className={classNames(
                              checked ? 'text-blue-700' : 'text-gray-500',
                              'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-left'
                            )}
                          >
                            {card.status === 'Payment Failed' ?
                              <>
                                <span className='mr-2 text-red-500'>{card.status}</span>
                                <button
                                  type="button"
                                  onClick={(event: any)=>this.resumeCard(event, card.id)}
                                  className="block rounded-md bg-blue-600 px-2 py-1 text-center text-xs font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                >
                                  Resume
                                </button>
                              </>
                            : card.status}
                          </span>
                          <span
                            className={classNames(
                              checked ? 'text-blue-700' : 'text-gray-500',
                              'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-left'
                            )}
                          >
                            **** **** **** {card.number}
                          </span>
                          <span
                            className={classNames(
                              checked ? 'text-blue-700' : 'text-gray-500',
                              'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-left'
                            )}
                          >
                            Expiry: {card.expiry}
                          </span>
                          <span
                            className={classNames(
                              checked ? 'text-blue-700' : 'text-gray-500',
                              'ml-6 pl-1 text-sm md:ml-0 md:pl-0'
                            )}
                          >
                            <TrashIcon className='w-4 h-4' onClick={(event: any)=>this.deleteCard(event, card.id)}/>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </fieldset>
            </div>
          : null}
          <div className="mt-4">
            <button
              type="button"
              onClick={()=>this.openAddCardModal()}
              className="block rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
            >
              Add Card
            </button>
          </div>
        </div>

        <div>
          <div className="mt-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    Billings
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Reason
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Billed at
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {this.state.billings.map((billing) => (
                  <tr key={billing.id}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-left text-gray-500">
                      ${billing.amount / 100000}
                    </td>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                      {billing.type}
                    </td>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500 sm:pl-0">
                      <Moment format="YYYY/MM/DD HH:mm:ss">
                        {billing.createdAt}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {this.addCardModal()}
        {this.reloadSetupModal()}
        <ErrorNotification error={this.state.addCardError} setError={()=>this.setState({addCardError: ''})} />

      </SettingsHolder>
    );
  }
}

export default function BillingWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Billing params={params} navigate={navigate}/>
}