import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Switch } from '@headlessui/react'
import DashboardHolder from './DashboardHolder'
import { Editable, useEditor } from "@wysimark/react"
import { UserCircleIcon, PhotoIcon } from '@heroicons/react/20/solid'
import { SEOHeaders } from "./SeoHeaders"

interface NewEditDashboardProps {
  navigate: any
  params: any
  editor: any
}

type NewEditDashboardStates = {
  name: string
  description: string
}

class NewEditDashboard extends Component <NewEditDashboardProps, NewEditDashboardStates> {
  constructor (props: NewEditDashboardProps) {
    super(props)
    this.state = {
      name: '',
      description: ''
    }
  }

  newNamespace = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.description
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.props.navigate('/d/'+json.id+'/p')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }
  
  render () {
    return (
      <DashboardHolder>
        <SEOHeaders title={'New Space'} appendTitle/>

        <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8 h-full">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/vortn-logo.svg"
              alt="Vortn"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create a new Space
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6">

              <div>
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600">
                    <input
                      type="text"
                      name="name"
                      className="block w-full flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      onChange={this.inputChange}
                      value={this.state.name}
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    onChange={this.inputChange}
                    value={this.state.description}
                  />
                </div>
              </div>

              <div className="mt-6">
                <button onClick={()=>this.newNamespace()} type="button" className="w-full rounded-md bg-blue-100 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
                  Create
                </button>
              </div>
            </form>

          </div>
        </div>
      </DashboardHolder>
    );
  }
}

export default function NewEditDashboardWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const editor = useEditor({})
  return <NewEditDashboard params={params} navigate={navigate} editor={editor}/>
}