import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import ProfileHolder from './ProfileHolder'
import { useEditor } from "@wysimark/react"
import { SEOHeaders } from "./SeoHeaders"
import { Error } from './Error'
import { Success } from './Success'

interface PasswordProps {
  navigate: any
  params: any
  editor: any
}

type PasswordStates = {
  errors: any
  error: string
  success: string
  oldpassword: string
  password: string
  repassword: string
}

class Password extends Component <PasswordProps, PasswordStates> {
  constructor (props: PasswordProps) {
    super(props)
    this.state = {
      errors: [],
      error: '',
      success: '',
      oldpassword: '',
      password: '',
      repassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as PasswordStates)
  }

  update = () => {
    this.setState({
      errors: '',
      error: '',
      success: ''
    })
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          oldPassword: this.state.oldpassword,
          newPassword: this.state.password,
          reNewPassword: this.state.repassword
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            success: 'Successfully Changed',
            oldpassword: '',
            password: '',
            repassword: ''
          })
        } else {
          this.setState({
            errors: json.errors,
            error: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <ProfileHolder>
        <SEOHeaders title={'Change Password'} appendTitle/>
        <div className="sm:w-2/3 w-full">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Old Password
            </label>
          </div>
          <div className="mt-2">
            <input
              id="password"
              name="oldpassword"
              type="password"
              autoComplete="current-password"
              value={this.state.oldpassword}
              onChange={this.inputChange}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-4 sm:w-2/3 w-full">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              New Password
            </label>
          </div>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.inputChange}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-4 sm:w-2/3 w-full">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Re-enter New Password
            </label>
          </div>
          <div className="mt-2">
            <input
              id="password"
              name="repassword"
              type="password"
              autoComplete="current-password"
              value={this.state.repassword}
              onChange={this.inputChange}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-6">
          <button
            type="button"
            className="flex justify-center rounded-md bg-blue-100 px-10 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
            onClick={()=>this.update()}
          >
            Update
          </button>
        </div>
        
        <Success logMessage={this.state.success} setLogMessage={(e:string)=>this.setState({success:e})} />
        <Error logError={this.state.error} setLogError={(e:string)=>this.setState({error:e})} />

      </ProfileHolder>
    );
  }
}

export default function PasswordWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const editor = useEditor({})
  return <Password params={params} navigate={navigate} editor={editor}/>
}