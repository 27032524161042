import React, { Component, createRef } from 'react'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Combobox } from '@headlessui/react'
import { useTranscriber } from "./useTranscriber"
import { useVoicer } from "./useVoicer"
import DashboardHolder from './DashboardHolder'
import {
  CheckIcon,
  SparklesIcon,
  SpeakerWaveIcon,
  MicrophoneIcon,
  LockOpenIcon,
  UserIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import io from "socket.io-client"
import { SEOHeaders } from "./SeoHeaders"
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MicVAD } from "@ricky0123/vad-web"

declare var window: any;
const audioOutput: string = 'SpeechSynthesys' // 'Xenova/speecht5_tts'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ChatViewProps {
  navigate: any
  params: any
  location: any
  transcriber: any
  voicer: any
}

type ChatViewStates = {
  chats: any[]
  threads: any[]
  speakingIndex: number
  text: string
  access: any[]
  pressrecording: boolean
  recording: boolean
  listening: boolean
  addAccessRunnerSearch: string
  addAccessSelectedResult: any
  addAccessRunnerResults: any[]
}

class ChatView extends Component <ChatViewProps, ChatViewStates> {

  socket: any
  speechSynthesisUtteranceArray: any[] = []
  scroller: any = createRef()
  textArea: any = createRef()
  vad: any
  sherpa: any

  constructor (props: ChatViewProps) {
    super(props)
    this.state = {
      chats: [],
      threads: [],
      speakingIndex: 0,
      text: '',
      access: [],
      pressrecording: false,
      recording: false,
      listening: false,
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: []
    }
  }

  componentDidMount(){
    if (this.props.params.namespace !== '') {
      this.connect()
    }
    this.loadAllGroups()
    // this.loadModels()
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  componentDidUpdate(prevProps: ChatViewProps, prevState: ChatViewStates) {
    if(prevProps.params.namespace !== this.props.params.namespace && this.props.params.namespace !== '') {
      if (this.socket) {
        this.socket.disconnect()
      }
      this.connect()
    }
    if(prevProps.params.thread !== this.props.params.thread && this.props.params.thread !== '') {
      if (this.socket) {
        this.loadThread()
      }
    }
    if(prevProps.params.thread !== this.props.params.thread && this.props.params.thread === undefined) {
      this.setState({chats: []})
    }
    if (
      prevProps.transcriber.processing === true &&
      this.props.transcriber.processing === false &&
      this.props.transcriber.output !== null
    ) {
      const text = this.props.transcriber.output
      if (text.length > 0) {
        this.setState({text}, ()=>this.newText())
      }
    }
  }
  
  loadThreads = async () => {
    if (this.socket) {
      this.socket.emit('getThreads')
    }
  }
  
  loadThread = async () => {
    if (this.socket && this.props.params.thread) {
      this.socket.emit('getThread', {id: this.props.params.thread})
    }
  }

  connect = async () => {
    const token = getCredentials()
    this.socket = io(config.app.wsUri, {
      transports: ["websocket"],
      reconnection: true,
      timeout: 2000,
      auth: {
        token,
        namespace: this.props.params.namespace
      }
    }).on('connect', () => {
      this.loadThreads()
      this.loadThread()
    }).on('newThread', (data: any) => {
      this.setState((prevState) => {return {threads: [...[data], ...prevState.threads]}}, ()=>this.props.navigate('/d/'+this.props.params.namespace+'/c/'+data.id))
    }).on('threads', (data: any) => {
      this.setState({threads: data.reverse()})
    }).on('thread', (data: any) => {
      this.setState({chats: data.chats, access: data.access}, ()=>this.scrollToBottom())
    }).on('chunk', (data: any) => {
      // handle chunks and speaking
      if (this.state.listening) {
        const index = data.message.indexOf(".", this.state.speakingIndex)
        if (index > this.state.speakingIndex) {
          const toProcess = data.message.substring(this.state.speakingIndex, index+1)
          this.setState({speakingIndex: index+1})
          // send to whatever is active
          if (audioOutput === 'Xenova/speecht5_tts' && this.props.voicer.ready) {
            this.props.voicer.process(toProcess)
          }
          if (audioOutput === 'SpeechSynthesys') {
            this.speechSynthesisUtteranceArray.push(toProcess)
            this.speakWithSpeechSynthesisUtterance()
          }
        }
      }
      // update chats
      if (data.threadId === this.props.params.thread) {
        this.setState((prevState) => {
          const newChats = prevState.chats
          const index = newChats.findIndex((chat)=>chat.id === data.id)
          if (index === -1) {
            newChats.push(data)
            return({chats: newChats})
          } else {
            newChats[index] = data
            return({chats: newChats})
          }
        }, ()=>this.scrollToBottom())
      }
    }).on('newChat', (data: any) => {
      if (data.threadId === this.props.params.thread) {
        const newChats = this.state.chats
        newChats.push(data)
        this.setState({chats: newChats}, ()=>this.scrollToBottom())
      }
    }).on("error", (error) => {
      // error
    }).on("connect_error", (error) => {
      // connect error
    }).on("disconnect", () => {
      // disconnect
    })
  }
  
  deleteThread = async (threadId: string) => {
    this.socket.emit('delThread', {id: threadId})
    const threads = this.state.threads
    const indexToRemove = threads.findIndex((thread)=>thread.id === threadId)
    threads.splice(indexToRemove, 1)
    this.setState({threads}, ()=>this.props.navigate(`/d/${this.props.params.namespace}/c`))
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  newText = async () => {
    await this.socket.emit('newText', { text: this.state.text, access: this.state.access, thread: this.props.params.thread ? this.props.params.thread : undefined })
    this.setState({text: ''}, ()=>this.adjustTextareaHeight())
  }

  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ addAccessRunnerSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ addAccessRunnerSearch: '', addAccessRunnerResults: [] } as any)
    }
  }

  addAccess = (group: any) => {
    if (group === null) return
    const access = this.state.access
    access.push(group)
    this.setState({
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: [],
      access
    })
    if (this.props.params.thread) {
      this.socket.emit('updateThread', {
        thread: this.props.params.thread,
        access
      })
    }
  }

  removeAccess = (id: string) => {
    const access = this.state.access
    const newaccess = access.filter(el => el.id !== id)
    this.setState({
      access: newaccess
    })
    if (this.props.params.thread) {
      this.socket.emit('updateThread', {
        thread: this.props.params.thread,
        access: newaccess
      })
    }
  }

  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.addAccessRunnerSearch) {
      search = '&search=' + this.state.addAccessRunnerSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            addAccessRunnerResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadAllGroups = () => {
    const authToken = getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({access: json.groups})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  recording: any = null
  mediaRecorder: any
  recordingInterval: any
  audioContext: any
  mediaStreamSource: any
  meter: any
  recordingChunks: any[] = []
  minVolume: number = 0.1
  silenceToSend: number = 200
  
  toggleRecording = () => {
    if (this.vad === undefined) return
    if (this.state.recording === false) {
      this.setState({recording: true}, ()=>this.vad.start())
    } else {
      this.setState({recording: false}, ()=>this.vad.pause())
    }
  }
  
  toggleListening = () => {
    if (this.state.listening === false) {
      this.setState({listening: true})
    } else {
      this.setState({listening: false})
    }
  }

  synth: any = undefined
  loadModels = async () => {
    if (audioOutput === 'Xenova/speecht5_tts') {
      this.props.voicer.load()
    }
    if (audioOutput === 'SpeechSynthesys' && window.speechSynthesis) {
      this.synth = window.speechSynthesis
    }
    this.props.transcriber.load()
    this.vad = await MicVAD.new({
      additionalAudioConstraints: {sampleRate: 16000, sampleSize: 32, channelCount: 1, echoCancellation: true} as any,
      positiveSpeechThreshold: 0.8,
      onSpeechEnd: async (audio: any) => {
        this.props.transcriber.start(audio)
      }
    })
  }
  
  speakWithSpeechSynthesisUtterance = () => {
    if (this.speechSynthesisUtteranceArray.length > 0 && this.synth !== undefined && this.synth.speaking === false) {
      const textToPlay = this.speechSynthesisUtteranceArray.shift()
      // const voices = this.synth.getVoices().filter((el: any) => el.name.includes('English'))
      // console.log('voices', voices)
      // const selectedVoice = voices.find((voice: any) => voice.name === 'Shelley (English (US))')
      const utterance = new SpeechSynthesisUtterance(textToPlay)
      utterance.pitch = 1
      utterance.rate = 1.25
      // utterance.voice = selectedVoice
      utterance.onend = () => {
        this.speakWithSpeechSynthesisUtterance()
      }
      this.synth.speak(utterance)
    }
  }

  scrollToBottom = () => {
    if (this.scroller.current) {
      this.scroller.current.scrollTop = this.scroller.current.scrollHeight
    }
  }
  
  handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.newText()
    }
  }
  
  handleTextAreaInput = (event: any) => {
    this.setState({text: event.target.value})
    this.adjustTextareaHeight()
  }

  adjustTextareaHeight = () => {
    const textarea = this.textArea.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight < 300 ? textarea.scrollHeight : 300}px`;
    }
  }
  
  render () {
    return (
      <DashboardHolder
        paddings={false}
        side={
          <div className={`w-[280px] border-r border-gray-200 overflow-scroll h-full`} >
            <div
              onClick={()=>{
                this.loadAllGroups()
                this.props.navigate('/d/'+this.props.params.namespace+'/c')
              }}
              className="text-gray-700 hover:text-gray-900 font-medium flex justify-between pt-6 px-6 cursor-pointer"
            >
              <div>New Chat</div>
              <PencilSquareIcon className='w-5 h-5 mt-0.5'/>
            </div>
            <div className='pt-5'>
              {this.state.threads.map((thread: any)=>{
                return <div key={thread.id} className='flex py-1 px-6 text-white hover:text-gray-700 justify-between'>
                  <Link to={'/d/'+this.props.params.namespace+'/c/'+thread.id} className="text-sm text-gray-500 hover:text-gray-700 truncate">{thread.title}</Link>
                  <TrashIcon className="w-4 h-4 mt-1 cursor-pointer" onClick={()=>this.deleteThread(thread.id)}/>
                </div>
              })}
            </div>
          </div>
        }
      >

        <div className='lg:pl-[280px] h-screen flex flex-col'>
          <SEOHeaders title={'Chat'} appendTitle/>
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/d/'+this.props.params.namespace+'/c'} className="text-gray-700 font-medium hover:text-gray-900">
                          Chat
                        </Link>
                      </div>
                    </li>
                    {this.props.params.thread ?
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-700" aria-hidden="true" />
                          <Link
                            to={'/d/'+this.props.params.namespace+'/c/'+this.props.params.thread}
                            className="ml-4 font-medium text-gray-700 hover:text-gray-900"
                          >
                            {this.state.threads.find((t: any)=>t.id === this.props.params.thread)?.title}
                          </Link>
                        </div>
                      </li>
                    :null}
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <div className='flex flex-row flex-wrap mt-3'>
                <div>
                  <LockOpenIcon className="mr-1.5 mt-2.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                </div>
                {this.state.access.map((access: any) => {
                  return <div key={access.id} className='pr-1 mt-1'>
                    <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                      {access.name}
                      <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                        <span className="sr-only">Remove</span>
                        <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>
                        <span className="absolute -inset-1" />
                      </button>
                    </span>
                  </div>
                })}
                <div>
                  <Combobox as="div" value={this.state.addAccessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                    <div className="relative">
                      <Combobox.Input
                        className="rounded-md border-0 bg-white mt-1 py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                        onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                        displayValue={(group: any) => ''}
                        placeholder='Access pages as...'
                      />
                      {this.state.addAccessRunnerResults.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                          {this.state.addAccessRunnerResults.map((group: any) => (
                            <Combobox.Option
                              key={group.id}
                              value={group}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                  active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-blue-600'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </Combobox>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-[100vw] lg:w-[calc(100vw-344px)] flex-col overflow-y-auto flex-1 pt-2 pb-4" ref={this.scroller}>
            {this.state.chats.map((chat: any, index: number) => {
              return <div key={chat.id}>
                {chat.role === 'assistant' && chat.content ?
                  <div className="flex gap-3 my-2 text-gray-600 text-sm flex-1 px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                      <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <div className="leading-relaxed w-full"><span className="block font-bold text-gray-700">AI </span><Markdown className='pt-1 markdownstyles' remarkPlugins={[remarkGfm]}>{chat.content}</Markdown></div>
                  </div>
                : null}
                {/*
                {chat.role === 'assistant' && Array.isArray(chat.tool_calls) && chat.tool_calls.length > 0 ?
                  <div className="flex gap-3 my-2 text-gray-600 text-sm flex-1 px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                      <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <div className="leading-relaxed"><span className="block font-bold text-gray-700">AI </span><div className='pt-1'>Processing with tool...</div></div>
                  </div>
                : null}
                {chat.role === 'tool' ?
                  <div className="flex gap-3 my-2 text-gray-600 text-sm flex-1 px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                      <PuzzlePieceIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <div className="leading-relaxed"><span className="block font-bold text-gray-700">Tool </span><div className='pt-1'>Tool execution complete...</div></div>
                  </div>
                : null}
                */}
                {chat.role === 'user' ?
                  <div className="flex gap-3 my-2 text-gray-600 text-sm flex-1 px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                      <UserIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                    </div>
                    <div className="leading-relaxed w-full"><span className="block font-bold text-gray-700">You </span><Markdown className='pt-1 markdownstyles' remarkPlugins={[remarkGfm]}>{chat.content}</Markdown></div>
                  </div>
                : null}
              </div>
            })}
            {this.state.chats.length === 0 ?
              <div className='w-full h-full flex justify-center items-center flex-col text-gray-500'>
                <div className='pb-7 pr-5'>Few of Vortn superpowers:</div>
                <ul className='list-disc pl-7 space-y-1'>
                  <li>Answer questions, write code</li>
                  <li>Retrieve contents of website</li>
                  <li>Execute python and javascript code</li>
                  <li>Use API calls to external services</li>
                  <li>Send email to self or members</li>
                </ul>
              </div>
            :null}
          </div>
          
          <div className="px-4 sm:px-6 lg:px-8">
            <form className="pl-10 pr-10 flex items-center justify-center w-full space-x-2" onSubmit={(event)=>{event.preventDefault();this.newText()}}>
              <div className='relative w-full mb-2'>
                <textarea
                  className="flex w-full text-gray-900 rounded-md border-0 pr-24 pl-3 py-2.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-blue-600 disabled:cursor-not-allowed disabled:opacity-50 resize-none"
                  rows={1}
                  ref={this.textArea}
                  value={this.state.text}
                  onChange={this.handleTextAreaInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder="Message Vortn..."
                />
                <button onClick={()=>this.newText()} type="button" className="absolute bottom-1 right-1 inline-flex items-center rounded-md bg-blue-100 px-3 py-1.5 font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
                  <SparklesIcon className="-ml-0.5 mr-1.5 h-4 w-4 text-blue-900" aria-hidden="true" />
                  Send
                </button>
              </div>

              { this.props.transcriber.ready ?
                <>
                  {this.state.recording ?
                    <button onClick={()=>this.toggleRecording()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-red-600 hover:bg-red-500">
                      <MicrophoneIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  :
                    <button onClick={()=>this.toggleRecording()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500">
                      <MicrophoneIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  }
                </>
              : null}

              { audioOutput === 'Xenova/speecht5_tts' && this.props.voicer.ready ?
                <>
                  {this.state.listening ?
                    <button onClick={()=>this.toggleListening()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-red-600 hover:bg-red-500">
                      <SpeakerWaveIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  :
                    <button onClick={()=>this.toggleListening()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500">
                      <SpeakerWaveIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  }
                </>
              : null}
              
              { this.synth && audioOutput === 'SpeechSynthesys' && window.speechSynthesis ?
                <>
                  {this.state.listening ?
                    <button onClick={()=>this.toggleListening()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-red-600 hover:bg-red-500">
                      <SpeakerWaveIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  :
                    <button onClick={()=>this.toggleListening()} type="button" className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500">
                      <SpeakerWaveIcon className="ml-1.5 mr-1.5 mt-0.5 mb-0.5 h-4 w-4 text-white" aria-hidden="true"/>
                    </button>
                  }
                </>
              : null}

              {this.state.listening && audioOutput === 'Xenova/speecht5_tts' ?
                <audio src={this.props.voicer.processed.length > 0 ? this.props.voicer.processed[0] : undefined} autoPlay={true} onEnded={()=>this.props.voicer.shift()} />
              : null}

            </form>
          </div>
          
        </div>

      </DashboardHolder>
    );
  }
}

export default function ChatViewWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const transcriber = useTranscriber()
  const voicer = useVoicer()
  return <ChatView params={params} navigate={navigate} location={location} transcriber={transcriber} voicer={voicer}/>
}