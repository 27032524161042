import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import EditorJS from '@editorjs/editorjs'
import {
  ChevronRightIcon,
  CircleStackIcon
} from '@heroicons/react/24/outline'
import { SEOHeaders } from "./SeoHeaders"
import { ErrorNotification } from './ErrorNotification'

interface PageopenedProps {
  navigate: any
  params: any
}

type PageopenedStates = {
  page: any
  content: any
  noaccess: boolean
  savingPageError: string
}

class PageOpened extends Component <PageopenedProps, PageopenedStates> {
  
  editorInstance: any = null
  
  constructor (props: PageopenedProps) {
    super(props)
    this.state = {
      page: undefined,
      content: undefined,
      noaccess: false,
      savingPageError: ''
    }
  }

  componentDidMount(){
    this.loadPage()
  }
  
  componentWillUnmount() {
    if (this.editorInstance) {
      this.editorInstance.destroy()
      this.editorInstance = null
    }
  }

  componentDidUpdate(prevProps: PageopenedProps, prevState: PageopenedStates) {
    if(prevProps.params.page !== this.props.params.page) {
      if (this.props.params.page) {
        this.loadPage()
      } else {
        this.setState({
          page: undefined,
          content: '',
          noaccess: false,
          savingPageError: ''
        })
      }
    }
  }
  
  loadPage = () => {
    this.setState({noaccess: false})
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages/'+this.props.params.page, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            page: json.page,
            content: json.page.content
          }, () => this.initEditor())
        }
        if (json.status === 'error' && json.message === 'No access') {
          this.setState({noaccess: true})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  initEditor = () => {
    if (this.editorInstance) {
      this.editorInstance.destroy()
    }
    this.editorInstance = new EditorJS({
      holder: "editorjs",
      autofocus: true,
      placeholder: 'Start typing here...',
      tools: {
        header: {
          class: require('@editorjs/header'),
          inlineToolbar: ['link'],
        },
        list: {
          class: require('@editorjs/list'),
          inlineToolbar: true,
        },
        // Add more tools as needed
      },
      data: this.state.content,
      onChange: async (api, event) => {
        const content = await api.saver.save()
        this.setState({content})
      },
    })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  savePage = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages/'+this.state.page.id, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          content: this.state.content
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          // kra
          console.log('saved')
        }
        if (json.status === 'error') {
          this.setState({savingPageError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <div className='h-full'>

        {this.state.page ?
          <div className='flex flex-col h-full'>
            <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>
              <div className="lg:flex lg:items-top lg:justify-between">
                <SEOHeaders title={'Page: '+(this.state.page ? this.state.page.name : '')} appendTitle/>
                <div className="min-w-0 flex-1">
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                      <li>
                        <div className="flex">
                          <Link to={'/d/'+this.props.params.namespace+'/p'}
                            className="font-medium text-gray-700 hover:text-gray-900"
                          >
                            Pages
                          </Link>
                        </div>
                      </li>
                      { this.state.page.parent ?
                        <li>
                          <div className="flex items-center">
                            <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-500" aria-hidden="true" />
                            <Link
                              to={'/d/'+this.props.params.namespace+'/p/'+this.state.page.parent.id}
                              className="ml-4 font-medium text-gray-700 hover:text-gray-900"
                            >
                              {this.state.page.parent.name}
                            </Link>
                          </div>
                        </li>
                      : null}
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-500" aria-hidden="true" />
                          <Link
                            to={'/d/'+this.props.params.namespace+'/p/'+this.state.page.id}
                            className="ml-4 font-medium text-gray-700 hover:text-gray-900"
                          >
                            {this.state.page.name}
                          </Link>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                  <button
                    type="button"
                    onClick={() => this.savePage()}
                    className="inline-flex items-center rounded-md bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
                  >
                    <CircleStackIcon className="-ml-0.5 mr-1.5 h-4 w-4 text-blue-900" aria-hidden="true" />
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div className='pt-4 flex-1 overflow-y-auto px-4 sm:px-6 lg:px-8'>
              <div id="editorjs"/>
            </div>
          </div>
        :null}
        
        {this.state.noaccess ?
          <div className='flex w-full h-[300px] justify-center items-center'>
            No access to page
          </div>
        :null}
        
        <ErrorNotification error={this.state.savingPageError} setError={()=>this.setState({savingPageError: ''})} />

      </div>
    );
  }
}

export default function PageOpenedWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <PageOpened params={params} navigate={navigate}/>
}