import React, { Component, Fragment } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import DashboardHolder from './DashboardHolder'
import { useEditor } from "@wysimark/react"
import { Dialog, Transition, Combobox } from '@headlessui/react'
import { Tree } from "react-arborist"
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { arrayToTree } from "performant-array-to-tree"
import { EllipsisVerticalIcon, LockClosedIcon, PencilSquareIcon, CheckIcon, ChevronRightIcon, PlusIcon, TrashIcon, XMarkIcon, Bars3Icon, ExclamationTriangleIcon, PlusCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import PageOpened from "./PageOpened"
import { Error } from './Error'
import { ErrorNotification } from './ErrorNotification'

const accessLevels: any = {
  '50': {desc: '(group can edit)', short: 'Edit'},
  '0': {desc: '(group can view)', short: 'View'},
  '-1': {desc: '(group user access)', short: 'Inherit'}
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function sortTree(array: any[]): any[] {
  array.sort((a, b) => a.order - b.order)
  array.forEach(obj => {
    if (obj.children && obj.children.length > 0) {
      obj.children = sortTree(obj.children)
    }
  })
  return array
}

interface DashboardProps {
  navigate: any
  params: any
  editor: any
}

type DashboardStates = {
  pages: any[]
  deletePageId: string
  deletingPageName: string
  deletingPageError: string
  addingEditingNewPage: boolean
  addingEditingPageId: string
  addingEditingPageParentId: string
  addingEditingPageName: string
  addingEditingPageAccess: any[]
  addingEditingPageError: string
  movingPageError: string
  accessSelectedResult: any
  accessSearch: string
  accessSearchResults: any[]
}

class Dashboard extends Component <DashboardProps, DashboardStates> {
  constructor (props: DashboardProps) {
    super(props)
    this.state = {
      pages: [],
      deletePageId: '',
      deletingPageName: '',
      deletingPageError: '',
      addingEditingNewPage: false,
      addingEditingPageId: '',
      addingEditingPageParentId: '',
      addingEditingPageName: '',
      addingEditingPageAccess: [],
      addingEditingPageError: '',
      movingPageError: '',
      accessSelectedResult: undefined,
      accessSearch: '',
      accessSearchResults: []
    }
  }

  componentDidMount(){
    this.loadNamespacePages()
  }

  componentDidUpdate(prevProps: DashboardProps, prevState: DashboardStates) {
    if(prevProps.params.namespace !== this.props.params.namespace) {
      this.loadNamespacePages()
    }
  }
  
  loadNamespacePages = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({ pages: sortTree(arrayToTree(json.pages, { dataField: null, parentId: "parent" })) })
          if (json.pages && json.pages.length > 0 && this.props.params.page === undefined) {
            this.props.navigate(`/d/${this.props.params.namespace}/p/${json.pages[0].id}`)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  onMove = async ({ dragIds, parentId, index }: any) => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          page: dragIds[0],
          index,
          parent: parentId !== null ? parentId : ''
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadNamespacePages()
        }
        if (json.status === 'error') {
          this.setState({movingPageError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  openAddEditPage = (page: any, parentId: string = '') => {
    if (page === '') {
      this.setState({
        addingEditingNewPage: true,
        addingEditingPageId: '',
        addingEditingPageName: '',
        addingEditingPageParentId: parentId,
        addingEditingPageAccess: [],
        addingEditingPageError: ''
      })
    } else {
      this.setState({
        addingEditingNewPage: true,
        addingEditingPageId: page.id,
        addingEditingPageName: page.name,
        addingEditingPageParentId: parentId,
        addingEditingPageAccess: page.access,
        addingEditingPageError: ''
      })
    }
  }

  addUpdatePage = async () => {
    const authToken = getCredentials()
    let editingAppend = ''
    if (this.state.addingEditingPageId) {
      editingAppend = '/' + this.state.addingEditingPageId
    }
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages' + editingAppend, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          name: this.state.addingEditingPageName,
          parent: this.state.addingEditingPageParentId,
          access: this.state.addingEditingPageAccess
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadNamespacePages()
          this.closeAddEditPage()
        } else {
          this.setState({addingEditingPageError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  addAccess = (group: any) => {
    if (group === null) return
    const addingEditingPageAccess = this.state.addingEditingPageAccess
    addingEditingPageAccess.push({...group, access: -1})
    this.setState({
      accessSearch: '',
      accessSelectedResult: undefined,
      accessSearchResults: [],
      addingEditingPageAccess
    })
  }

  removeAccess = (id: string) => {
    const addingEditingPageAccess = this.state.addingEditingPageAccess
    const newaccess = addingEditingPageAccess.filter(el => el.id !== id)
    this.setState({
      addingEditingPageAccess: newaccess
    })
  }
  
  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ accessSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ accessSearch: '', accessSearchResults: [] } as any)
    }
  }
  
  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.accessSearch) {
      search = '&search=' + this.state.accessSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            accessSearchResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  closeAddEditPage = () => {
    this.setState({
      addingEditingNewPage: false,
      addingEditingPageName: '',
      addingEditingPageParentId: '',
      addingEditingPageError: '',
      addingEditingPageAccess: [],
      accessSearch: '',
      accessSearchResults: [],
      accessSelectedResult: undefined
    })
  }

  openDeletePage = async (pageId: string, pageName: string) => {
    this.setState({deletePageId: pageId, deletingPageName: pageName, deletingPageError: ''})
  }

  deletePage = async () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/pages/'+this.state.deletePageId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadNamespacePages()
          this.closeDeletePage()
          this.props.navigate(`/d/${this.props.params.namespace}/p`)
        } else {
          this.setState({deletingPageError: json.message})
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  setAccessLevel = async (accessId: string, level: string) => {
    const accesses = this.state.addingEditingPageAccess
    const access = accesses.find(acc => acc.id === accessId)
    if (access) access.access = level
    this.setState({addingEditingPageAccess: accesses})
  }

  closeDeletePage = async () => {
    this.setState({deletePageId: '', deletingPageName: '', deletingPageError: ''})
  }

  removeAccessPage = (id: string) => {
    const accesses = this.state.addingEditingPageAccess.filter((acc: any) => acc.id !== id)
    this.setState({addingEditingPageAccess: accesses})
  }

  node = ({ node, style, dragHandle }: any) => {
    return (
      <div style={style} className='flex flex-row text-white group hover:text-gray-700 pr-2'>
        <div ref={dragHandle} className='w-2 mr-1.5 ml-2.5 overflow-hidden'>
          <Bars3Icon className="h-5 w-5 -ml-2 cursor-grab group-hover:text-gray-700"/>
        </div>
        <Link className='pl-0.5 pr-6 text-sm text-gray-500 group-hover:text-gray-700 truncate relative' to={'/d/'+this.props.params.namespace+'/p/'+node.id}>
          {node.data.name}
          {node.children.length > 0 ?
            <div className='cursor-pointer absolute right-0 top-0' onClick={(event) => {event.preventDefault(); node.toggle(event)}}>
              <ChevronRightIcon
                className={classNames(!node.isClosed ? 'rotate-90' : '','h-4 w-4 mt-1 text-gray-500 group-hover:text-gray-700')}
                aria-hidden="true"
              />
            </div>
          :
            null
          }
        </Link>
        <Menu as="div" className="relative inline-block ml-auto">
          <div>
            <MenuButton className="flex items-center">
              <EllipsisVerticalIcon className="h-5 w-5 mt-0.5 group-hover:text-gray-700" />
            </MenuButton>
          </div>
          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white border border-grey-200 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem>
                <div className="flex px-3 py-2 text-sm text-gray-900 font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={() => this.openAddEditPage('', node.data.id)}>
                  <PlusIcon className="h-4 w-4 mt-0.5 mr-3 group-hover:text-gray-700"/>
                  <div>Add Subpage</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex px-3 py-2 text-sm text-gray-900 font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={() => this.openAddEditPage(node.data, node.data.id)}>
                  <PencilSquareIcon className="h-4 w-4 mt-0.5 mr-3 group-hover:text-gray-700"/>
                  <div>Edit Page</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex px-3 py-2 text-sm text-gray-900 font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer" onClick={() => this.openDeletePage(node.data.id, node.data.name)}>
                  <TrashIcon className="h-4 w-4 mt-0.5 mr-3 group-hover:text-gray-700"/>
                  <div>Delete Page</div>
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    );
  }

  deletePageModal = () => {
    return (
      <Transition.Root show={this.state.deletePageId !== ''} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeDeletePage()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={()=>this.closeDeletePage()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete Page
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete a page named {this.state.deletingPageName}? Page will be permanently removed
                          from our servers forever. This action cannot be undone.
                        </p>
                      </div>
                      <div className="mt-7 flex">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={()=>this.closeDeletePage()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 ml-3"
                          onClick={()=>this.deletePage()}
                        >
                          Delete
                        </button>
                      </div>
                      <Error logError={this.state.deletingPageError} setLogError={()=>this.setState({deletingPageError: ''})} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  addEditPageModal = () => {
    return (
      <Transition.Root show={this.state.addingEditingNewPage} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>this.closeAddEditPage()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-0"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-0"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={()=>this.closeAddEditPage()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      {this.state.addingEditingPageId ?
                        <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                      :
                        <PlusCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                      }
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {this.state.addingEditingPageId ? 'Edit Page' : 'New Page'}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {this.state.addingEditingPageId ?
                            'You are editing a page. Remember to add/edit access bellow.'
                            :
                            'You are creating a new page. Remember to setup access bellow.'
                          }
                        </p>
                      </div>
                      <div className="mt-5">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        {this.state.addingEditingPageId ? 'Page Name' : 'New Page Name'}
                        </label>
                        <div className="mt-1">
                          <input
                            name="addingEditingPageName"
                            id="addingEditingPageName"
                            value={this.state.addingEditingPageName}
                            onChange={this.inputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Marketing"
                          />
                        </div>
                      </div>
                      <div className="mt-5">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                          Owners & Access
                        </label>
                        <div className='flex flex-row pr-4 flex-wrap'>
                          {this.state.addingEditingPageAccess.map((access: any) => {
                            return <div key={access.id} className='pr-1 mt-1'>
                              <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                {access.name}
                                <Menu as="div" className="relative inline-block text-left ml-1">
                                  <div>
                                    <MenuButton className="flex items-center rounded-sm hover:bg-red-600/20 text-red-600/50 hover:text-red-600/75 focus:outline-none">
                                      <LockClosedIcon aria-hidden="true" className="h-3 w-3" />
                                      {accessLevels[access.access].short}
                                    </MenuButton>
                                  </div>
                                  <MenuItems
                                    transition
                                    className="absolute left-0 top-5 z-10 mt-2 w-56 origin-top-left rounded-sm bg-white ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                  >
                                    <div className="py-1">
                                      {Object.keys(accessLevels).map((key: any)=>
                                        <MenuItem>
                                          <div
                                            className='block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer'
                                            onClick={()=>this.setAccessLevel(access.id, key)}
                                          >
                                            {accessLevels[key].short} <span className="text-sm text-gray-500">{accessLevels[key].desc}</span>
                                          </div>
                                        </MenuItem>
                                      )}
                                    </div>
                                  </MenuItems>
                                </Menu>
                                <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                                  <span className="sr-only">Remove</span>
                                  <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                  </svg>
                                  <span className="absolute -inset-1" />
                                </button>
                              </span>
                            </div>
                          })}
                          <div>
                            <Combobox as="div" value={this.state.accessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                              <div className="relative">
                                <Combobox.Input
                                  className="rounded-md border-0 bg-white py-2 px-2.5 mt-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                                  onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                                  displayValue={(group: any) => ''}
                                  placeholder='Search owners ...'
                                />
                                {this.state.accessSearchResults.length > 0 && (
                                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                                    {this.state.accessSearchResults.map((group: any) => (
                                      <Combobox.Option
                                        key={group.id}
                                        value={group}
                                        className={({ active }) =>
                                          classNames(
                                            'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>
                                            {selected && (
                                              <span
                                                className={classNames(
                                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                                  active ? 'text-white' : 'text-blue-600'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </div>
                            </Combobox>
                          </div>
                        </div>
                      </div>
                      <div className="mt-7 flex">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={()=>this.closeAddEditPage()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-blue-100 px-10 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 ml-3 w-auto"
                          onClick={()=>this.addUpdatePage()}
                        >
                          {this.state.addingEditingPageId ? 'Update' : 'Create'}
                        </button>
                      </div>
                      <Error logError={this.state.addingEditingPageError} setLogError={()=>this.setState({addingEditingPageError: ''})} />
                    </div>                  
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  render () {
    return (
      <DashboardHolder
        side={
          <div className={`w-[280px] overflow-scroll border-r border-gray-200 overflow-scroll h-full`}>
            <div className="text-gray-700 hover:text-gray-900 font-medium flex justify-between pt-6 px-6 cursor-pointer" onClick={()=>this.openAddEditPage('')}>
              <div>New Page</div>
              <PencilSquareIcon className='w-5 h-5 mt-0.5'/>
            </div>
            <div className='pt-5'>
              <Tree
                openByDefault={true}
                rowHeight={30}
                width={'100%'}
                indent={15}
                height={window.innerHeight-75}
                data={this.state.pages}
                onMove={this.onMove}
              >
                {this.node}
              </Tree>
            </div>
          </div>
        }
      >

        <div className="lg:pl-[280px] h-full">
          <PageOpened/>
        </div>

        {this.addEditPageModal()}
        {this.deletePageModal()}
        <ErrorNotification error={this.state.movingPageError} setError={()=>this.setState({movingPageError: ''})} />

      </DashboardHolder>
    );
  }
}

export default function DashboardWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const editor = useEditor({})
  return <Dashboard params={params} navigate={navigate} editor={editor}/>
}

