import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { Switch, Combobox } from '@headlessui/react'
import DashboardHolder from './DashboardHolder'
import { ChevronRightIcon, ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import {
  MagnifyingGlassIcon,
  CheckIcon,
  UserIcon,
  SparklesIcon
} from '@heroicons/react/24/outline'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { SEOHeaders } from "./SeoHeaders"

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function overflown (text: string) {
  return text.split('\n').length > 4 || text.length > 500
}

const feedsPerPage = 25

interface FeedViewProps {
  navigate: any
  params: any
}

type FeedViewStates = {
  feeds: any[]
  access: any[]
  feedsCount: number
  page: number
  addAccessRunnerSearch: string
  addAccessSelectedResult: any
  addAccessRunnerResults: any[]
  expanded: any[]
}

class FeedView extends Component <FeedViewProps, FeedViewStates> {
  constructor (props: FeedViewProps) {
    super(props)
    this.state = {
      feeds: [],
      access: [],
      feedsCount: 0,
      page: 0,
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: [],
      expanded: []
    }
  }

  componentDidMount(){
    this.loadAllGroups()
  }

  loadFeed = () => {
    const authToken = getCredentials()
    const accessArray = this.state.access.map((acc)=>acc.id)
    fetch(
      config.app.apiUri + '/api/v1/namespace/'+this.props.params.namespace+'/feeds?limit='+feedsPerPage+'&offset='+this.state.page*feedsPerPage+'&access='+accessArray.join(','), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            feeds: json.feeds,
            feedsCount: json.count
          })
        } else {
          this.setState({
            feeds: []
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  searchAccessGroupChange = (value: any) => {
    if (value !== '') {
      this.setState({ addAccessRunnerSearch: value } as any, () => this.loadGroups())
    } else {
      this.setState({ addAccessRunnerSearch: '', addAccessRunnerResults: [] } as any)
    }
  }

  addAccess = (group: any) => {
    if (group === null) return
    const access = this.state.access
    access.push(group)
    this.setState({
      addAccessRunnerSearch: '',
      addAccessSelectedResult: undefined,
      addAccessRunnerResults: [],
      access
    }, ()=>this.loadFeed())
  }

  removeAccess = (id: string) => {
    const access = this.state.access
    const newaccess = access.filter(el => el.id !== id)
    this.setState({
      access: newaccess
    }, ()=>this.loadFeed())
  }

  loadGroups = () => {
    const authToken = getCredentials()
    let search = ''
    if (this.state.addAccessRunnerSearch) {
      search = '&search=' + this.state.addAccessRunnerSearch
    }
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            addAccessRunnerResults: json.groups
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  loadAllGroups = () => {
    const authToken = getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/me/groups?namespace=${this.props.params.namespace}&limit=10&offset=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({access: json.groups}, ()=>this.loadFeed())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  expandFeed = (id: string) => {
    const expanded = this.state.expanded
    expanded.push(id)
    this.setState({expanded})
  }

  render () {
    return (
      <DashboardHolder>

        <div className='h-screen flex flex-col'>
          <SEOHeaders title={'Feed'} appendTitle/>
          <div className='px-4 pt-10 sm:px-6 lg:px-8 lg:pt-6'>

            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to={'/d/'+this.props.params.namespace+'/f'
                        } className="font-medium text-gray-700 hover:text-gray-900">
                          Feed
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className='flex flex-row flex-wrap mt-3'>
              <div>
                <MagnifyingGlassIcon className="mr-1.5 mt-2.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </div>
              {this.state.access.map((access: any) => {
                return <div key={access.id} className='pr-1 mt-1'>
                  <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2.5 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    {access.name}
                    <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20" onClick={()=>this.removeAccess(access.id)}>
                      <span className="sr-only">Remove</span>
                      <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                        <path d="M4 4l6 6m0-6l-6 6" />
                      </svg>
                      <span className="absolute -inset-1" />
                    </button>
                  </span>
                </div>
              })}
              <div>
                <Combobox as="div" value={this.state.addAccessSelectedResult} onChange={(group: any) => this.addAccess(group)} >
                  <div className="relative">
                    <Combobox.Input
                      className="rounded-md mt-1 border-0 bg-white py-2 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs font-medium"
                      onChange={(event) => this.searchAccessGroupChange(event.target.value)}
                      displayValue={(group: any) => ''}
                      placeholder='Display from...'
                    />
                    {this.state.addAccessRunnerResults.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 focus:outline-none sm:text-sm">
                        {this.state.addAccessRunnerResults.map((group: any) => (
                          <Combobox.Option
                            key={group.id}
                            value={group}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                active ? 'bg-blue-600 text-white' : 'text-gray-900'
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span className={classNames('block truncate', selected && 'font-semibold')}>{group.name}</span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                      active ? 'text-white' : 'text-blue-600'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
            </div>
          </div>

          <ul className="flex flex-col overflow-y-auto flex-1 px-4 sm:px-6 lg:px-8 pt-2 pb-4">
            {this.state.feeds.map((feed) => {
              if (feed.meta && feed.meta.thread) {
                return <div key={feed.id} className="flex gap-3 my-2 text-gray-600 text-sm">
                  <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                    <UserIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                  </div>
                  <div className="leading-relaxed w-full">
                    <span className="block font-bold text-gray-700">{feed.meta.thread.fullname}<span className="font-normal text-gray-500"> from </span><Link to={`/d/${feed.meta.thread.namespaceId}/c/${feed.meta.thread.id}`}>chat</Link></span>
                    <Markdown className={!this.state.expanded.includes(feed.id) ? 'pt-1 markdownstyles max-h-32 overflow-hidden' : 'pt-1 markdownstyles'} remarkPlugins={[remarkGfm]}>{feed.text}</Markdown>
                    {overflown(feed.text) && !this.state.expanded.includes(feed.id) ? <div className="cursor-pointer" onClick={()=>this.expandFeed(feed.id)}>More...</div> : null}
                  </div>
                </div>
              }
              if (feed.meta && feed.meta.agent) {
                return <div key={feed.id} className="flex gap-3 my-2 text-gray-600 text-sm">
                  <div className="flex items-center justify-center rounded-full bg-gray-100 border p-1 w-8 h-8">
                    <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div className="leading-relaxed w-full">
                    <span className="block font-bold text-gray-700"><Link to={`/d/${feed.meta.agent.namespaceId}/a/${feed.meta.agent.agentId}`}>{feed.meta.agent.title}</Link><span className="font-normal text-gray-500"> from run </span><Link to={`/d/${feed.meta.agent.namespaceId}/a/${feed.meta.agent.agentId}/${feed.meta.agent.id}`}>#{feed.meta.agent.id}</Link></span>
                    <Markdown className={!this.state.expanded.includes(feed.id) ? 'pt-1 markdownstyles max-h-32 overflow-hidden' : 'pt-1 markdownstyles'} remarkPlugins={[remarkGfm]}>{feed.text}</Markdown>
                    {overflown(feed.text) && !this.state.expanded.includes(feed.id) ? <div className="cursor-pointer" onClick={()=>this.expandFeed(feed.id)}>More...</div> : null}
                  </div>
                </div>
              }
              return null
            })}
          </ul>

          {this.state.feedsCount > feedsPerPage ?
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1">
                <Link
                  to={'#'}
                  onClick={()=>this.setState({page: this.state.page > 0 ? this.state.page-1 : 0},()=>this.loadFeed())}
                  className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Previous
                </Link>
              </div>
              <div className="hidden md:-mt-px md:flex">
                {Array.from(Array(Math.ceil(this.state.feedsCount/feedsPerPage)).keys()).map((el: number) => {
                  return <Link
                    key={el}
                    to={"#"}
                    onClick={()=>this.setState({page: el},()=>this.loadFeed())}
                    className={this.state.page !== el ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" : "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"}
                  >
                    {el+1}
                  </Link>
                })}
              </div>
              <div className="-mt-px flex w-0 flex-1 justify-end">
                <Link
                  to={"#"}
                  onClick={()=>this.setState({page: this.state.page < Math.ceil(this.state.feedsCount / feedsPerPage) ? this.state.page+1 : this.state.page},()=>this.loadFeed())}
                  className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  Next
                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Link>
              </div>
            </nav>
          : null}
        </div>
      </DashboardHolder>
    );
  }
}

export default function FeedViewWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <FeedView params={params} navigate={navigate}/>
}